// import { upload } from "@testing-library/user-event/dist/upload";
// import React, { useState } from "react";
// import classNames from "../../classNames";
// // import classNames from "../../../../classNames";
// import { saveFileUseCase } from "../../domain/file";
// // import { saveFileUseCase } from "../../../../domain/file";

// const defaultProps = {
//   object: {},
// };

// function InputFile({ className, field, object, showError, accept, ...props }) {
//   const classes = classNames("form-control", className);
//   const value = object[field];

//   const [fileSizeError, setFileSizeError] = useState([])

//   function onChange(e) {

//     const files = Array.prototype.slice.call(e.target.files);

//     const allowedTypes = accept.split(',')

//     let isMatch = false;

//     for (const file of e.target.files){

//       for (let i = 0; i < allowedTypes.length; i++) {
//         const allowedType = allowedTypes[i].trim();

//         console.log(`${file.type} === ${allowedType}`)

//         if (allowedType === '*') {

//           isMatch = true;
//           break;
//         } else if (allowedType.startsWith('image/')) {

//           if (file.type.startsWith('image/')) {
//             isMatch = true;
//             break;
//           }
//         } else if (file.type === allowedType || allowedType === `.${file.type.split('/')[1]}`) {
//           // Exact match
//           isMatch = true;
//           break;
//         } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//           isMatch = true
//           break;
//         }
//       }

//     }

//     if(!isMatch){
//       showError('Error: Invalid File Type: Please choose a file with one of the allowed formats: DOC/DOCX, XLS/XLSX, PNG/JPEG/GIF, TXT/RTF.')
//       e.target.value = null
//       return
//     }

//     const limitFileSize = files.filter((file) => {
//       const fileSizeInBytes = file.size;
//       const maxSizeInBytes = 5048576; // 1MB

//       if (fileSizeInBytes > maxSizeInBytes) {
//         return file.name
//       }

//       return false
//     });

//     console.log(limitFileSize.length)

//     if(limitFileSize.length > 0){
//       showError(`file limit exceeded`)
//       e.target.value = null
//       return
//     }

//     if (files.length > 0) {
//       let images = [];
//       // let imagesGroup = [];
//       for (let item = 0; item < files.length; item++) {
//         saveFileUseCase()
//           .execute(files[item])
//           .then((result) => {
//             // images = {
//             //   name : result.name,
//             //   url : result.url
//             // }
//             images.push(result.url);
//             object[field] = images;
//           })
//           .catch((error) => {
//             console.log(error);
//           });
//       }
//     }
//     // console.log(object);
//   }

//   // const handleUploadFiles = (files) => {
//   //   const uploaded = value;
//   //   files.some((file) => {
//   //     uploaded.push(file);
//   //   });
//   //   console.log(uploaded);
//   // };

//   return (
//     <input
//       type="file"
//       defaultValue={value}
//       className={classes}
//       name={field}
//       onChange={onChange}
//       multiple
//       accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"
//       {...props}
//     />
//   );
// }

// InputFile.defaultProps = defaultProps;

// export default InputFile;

// import { upload } from "@testing-library/user-event/dist/upload";
// import React, { useState } from "react";
// import classNames from "../../classNames";
// // import classNames from "../../../../classNames";
// import { saveFileUseCase } from "../../domain/file";
// // import { saveFileUseCase } from "../../../../domain/file";
// // Depending on your project structure, adjust the import path for `classNames` and `saveFileUseCase` as needed.

// const defaultProps = {
//   object: {},
// };

// function InputFile({ className, field, object, showError, accept, ...props }) {
//   const classes = classNames("form-control", className);

//   // State to store the accumulated file names
//   const [fileNames, setFileNames] = useState("");

//   function onChange(e) {
//     const files = Array.from(e.target.files);
//     const allowedTypes = accept.split(",");
//     let fileSizeExceeded = false;
//     let invalidFileType = false;
//     let newFileNames = [];

//     files.forEach((file) => {
//       // Check if file type matches allowed types
//       let isMatch = allowedTypes.some((allowedType) => {
//         if (allowedType === "*") {
//           return true;
//         } else if (
//           allowedType.startsWith("image/") &&
//           file.type.startsWith("image/")
//         ) {
//           return true;
//         } else if (
//           file.type === allowedType ||
//           file.type.endsWith(allowedType.replace(".", ""))
//         ) {
//           return true;
//         } else if (
//           [
//             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//           ].includes(file.type)
//         ) {
//           return true;
//         }
//         return false;
//       });

//       if (!isMatch) {
//         invalidFileType = true;
//         return; // Skip further processing for this file
//       }

//       // Check if file size exceeds the limit (5MB in this case)
//       if (file.size > 5048576) {
//         // 5 * 1024 * 1024
//         fileSizeExceeded = true;
//         return; // Skip further processing for this file
//       }

//       newFileNames.push(file.name); // Accumulate valid file names
//     });

//     if (invalidFileType) {
//       showError(
//         "Error: Invalid File Type. Please choose a file with one of the allowed formats."
//       );
//       e.target.value = null; // Reset file input
//       return;
//     }

//     if (fileSizeExceeded) {
//       showError(
//         "Error: File size limit exceeded. Please select files smaller than 5MB."
//       );
//       e.target.value = null; // Reset file input
//       return;
//     }

//     // If all selected files are valid, proceed to save and update file names
//     if (files.length > 0) {
//       files.forEach((file) => {
//         saveFileUseCase()
//           .execute(file)
//           .then((result) => {
//             // Assuming `saveFileUseCase` properly saves the file and returns a result
//             object[field] = [...(object[field] || []), result.url]; // Accumulate file URLs in the object
//             setFileNames((prev) =>
//               prev ? `${prev}, ${file.name}` : file.name
//             ); // Accumulate file names in state
//           })
//           .catch((error) => {
//             console.log(error);
//             showError("An error occurred while saving the file.");
//           });
//       });
//     }
//   }
//   console.log("files", fileNames);

//   return (
//     <div>
//       <input
//         type="file"
//         className={classes}
//         name={field}
//         onChange={onChange}
//         multiple
//         accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"
//         {...props}
//       />
//       <div className="file-names-display fw-bold">List Files:</div>
//       <div className="file-names-display">{fileNames}</div>
//     </div>
//   );
// }

// InputFile.defaultProps = defaultProps;

// export default InputFile;

// import React, { useState } from "react";
// import classNames from "../../classNames";
// import { saveFileUseCase } from "../../domain/file";

// const defaultProps = {
//   object: {},
// };

// function InputFile({ className, field, object, showError, accept, ...props }) {
//   const classes = classNames("form-control", className);
//   // State to store the file objects { name: string, id: number }
//   const [filesData, setFilesData] = useState([]);

//   function onChange(e) {
//     const files = Array.from(e.target.files);
//     const allowedTypes = accept.split(",");
//     let fileSizeExceeded = false;
//     let invalidFileType = false;
//     let newFilesData = [...filesData];

//     files.forEach((file, index) => {
//       let isMatch = allowedTypes.some((allowedType) => {
//         return (
//           allowedType === "*" ||
//           file.type === allowedType ||
//           (allowedType.startsWith("image/") &&
//             file.type.startsWith("image/")) ||
//           file.type.endsWith(allowedType.replace(".", "")) ||
//           [
//             "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//           ].includes(file.type)
//         );
//       });

//       if (!isMatch) {
//         invalidFileType = true;
//       } else if (file.size > 5048576) {
//         fileSizeExceeded = true;
//       } else {
//         newFilesData.push({ name: file.name, id: filesData.length + index });
//       }
//     });

//     if (invalidFileType) {
//       showError(
//         "Error: Invalid File Type. Please choose a file with one of the allowed formats."
//       );
//     } else if (fileSizeExceeded) {
//       showError(
//         "Error: File size limit exceeded. Please select files smaller than 5MB."
//       );
//     } else {
//       setFilesData(newFilesData);
//     }

//     e.target.value = null; // Reset file input to allow re-upload of same file if needed
//   }

//   function deleteFile(fileId) {
//     setFilesData(filesData.filter((file) => file.id !== fileId));
//   }

//   return (
//     <div>
//       <input
//         type="file"
//         className={classes}
//         name={field}
//         onChange={onChange}
//         multiple
//         accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"
//         {...props}
//       />
//       <div className="file-names-display fw-bold">List Files:</div>
//       <ul>
//         {filesData.map((file) => (
//           <li
//             key={file.id}
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <span>{file.name}</span>
//             <button
//               type="button"
//               onClick={() => deleteFile(file.id)}
//               className="btn btn-link text-danger p-0 m-0"
//             >
//               <i className="bi-trash text-danger"></i>
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// InputFile.defaultProps = defaultProps;

// export default InputFile;

import React, { useState } from "react";
import classNames from "../../classNames";
import { saveFileUseCase } from "../../domain/file";

const defaultProps = {
  object: {},
};

function InputFile({ className, field, object, showError, accept, ...props }) {
  const classes = classNames("form-control", className);
  // State to store the file objects { name: string, id: number }
  const [filesData, setFilesData] = useState([]);

  function onChange(e) {
    const files = Array.from(e.target.files);
    const allowedTypes = accept.split(",");
    let fileSizeExceeded = false;
    let invalidFileType = false;
    let newFilesData = [...filesData];

    files.forEach((file, index) => {
      let isMatch = allowedTypes.some((allowedType) => {
        return (
          allowedType === "*" ||
          file.type === allowedType ||
          (allowedType.startsWith("image/") &&
            file.type.startsWith("image/")) ||
          file.type.endsWith(allowedType.replace(".", "")) ||
          [
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ].includes(file.type)
        );
      });

      if (!isMatch) {
        invalidFileType = true;
      } else if (file.size > 5048576) {
        fileSizeExceeded = true;
      } else {
        newFilesData.push({ name: file.name, id: filesData.length + index });
      }
    });

    if (invalidFileType) {
      showError(
        "Error: Invalid File Type. Please choose a file with one of the allowed formats."
      );
    } else if (fileSizeExceeded) {
      showError(
        "Error: File size limit exceeded. Please select files smaller than 5MB."
      );
    } else {
      setFilesData(newFilesData);
    }

    e.target.value = null; // Reset file input to allow re-upload of same file if needed
  }

  function deleteFile(fileId) {
    setFilesData(filesData.filter((file) => file.id !== fileId));
  }

  return (
    <div>
      <input
        type="file"
        className={classes}
        name={field}
        onChange={onChange}
        multiple
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"
        {...props}
      />
      <div className="file-names-display fw-bold">List Files:</div>
      <ul>
        {filesData.map((file) => (
          <li
            key={file.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>{file.name}</span>
            <button
              type="button"
              onClick={() => deleteFile(file.id)}
              className="btn btn-link text-danger p-0 m-0"
            >
              <i className="bi-trash text-danger"></i>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

InputFile.defaultProps = defaultProps;

export default InputFile;
