import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import "./orderHistory.css";
import orderDetailsPresenter from "./orderDetailsPresenter";
import { findObjectUseCase } from "../../domain/object";
import formatCurrency from "../../formatCurrency";
import Queue from "nq";
import { Scroll } from "nq-component";
import SkeletonElement from "../../components/Skeletons/SkeletenElement";
import { Link } from "react-router-dom";
import withContext from "../../withContext";
import { Button } from "nq-component";
import dateFormat from "../../dateFormat";

class OrderDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new orderDetailsPresenter(this, findObjectUseCase());
    this.state = {
      transactions: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setMore(hasMore) {
    this.setState({ hasMore });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  setTransactions(transactions) {
    this.setState({ transactions });
  }

  onClickProduct(product) {
    this.presenter.productClick(product);
  }

  onClickGoback(product) {
    this.presenter.goBackClick(product);
  }

  onClickProductDetails(product) {
    this.presenter.productDetailClick(product);
  }

  goBack() {
    this.navigateBack();
  }

  payNow() {
    this.presenter.payNow(this.state);
  }

  renderName(firstName, middleName, lastName) {
    const firstname = firstName === undefined ? "" : firstName;
    const middlename = middleName === undefined ? "" : middleName;
    const lastname = lastName === undefined ? "" : lastName;
    return `${firstname} ${middlename} ${lastname}`;
  }

  calculateTotalAmount(transactions) {
    let total = 0;
    for (const item of transactions) {
      total += parseInt(item?.payment?.amount);
    }
    return total;
  }

  render() {
    const user = this.getCurrentUser();
    const transactions = this.state.transactions;
    const hasMore = this.state.hasMore;
    const count = this.state.count;
    const progress = this.state.progress;
    console.log(transactions);
    console.log(user);
    return (
      <>
        <main
          className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2"
            style={{ maxWidth: "1000px" }}
          >
            <div className="d-flex flex-column justify-content-center mb-3 ">
              <h2
                className="d-flex align-items-center px-2"
                style={{ textShadow: "2px 2px 2px #FFFFFF" }}
              >
                <span className="mb-1">
                  <i class="bi bi-cart-check"></i>
                </span>
                Transaction Details
              </h2>
              {transactions.map((product) => {
                // console.log(product)
                return (
                  <>
                    {product.orders.map((order, index) => {
                      return (
                        <>
                          <div
                            className="accordion mb-3 product-services"
                            id={`accord${index}`}
                          >
                            <div className="accordion-item card-ps mb-3">
                              <h2
                                className="accordion-header"
                                id={`heading${index}`}
                              >
                                <button
                                  className="accordion-button bg-white py-2 "
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapse${index}`}
                                  aria-expanded="false"
                                  aria-controls={`collapse${index}`}
                                  style={{ borderRadius: "20px" }}
                                >
                                  <div className=" d-flex bg-white align-items-center">
                                    <img
                                      src={order.product.image}
                                      height="120"
                                      width="120"
                                      alt=""
                                      className="m-2 me-1"
                                      style={{ cursor: "pointer" }}
                                    />
                                    <div className="d-flex flex-column m-3 ms-1 me-1 w-100">
                                      <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                                        <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center">
                                          <span className="text-truncate">
                                            {order.product.name}
                                            <span className="text-success mx-1 text-truncate">
                                              {formatCurrency(
                                                order.product.price
                                              )}
                                            </span>
                                          </span>

                                          <span className="fs-6 mx-1 text-truncate">
                                            {"Coverage"}
                                          </span>
                                        </h5>
                                      </div>
                                      <h6 className="mb-0 text-limit">
                                        {order.product.description}
                                      </h6>
                                    </div>
                                  </div>
                                </button>
                              </h2>

                              <div
                                id={`collapse${index}`}
                                className="accordion-collapse collapse show"
                                aria-labelledby={`heading${index}`}
                                data-bs-parent={`#accord${index}`}
                              >
                                <div className="accordion-body">
                                  <div className="d-flex flex-column">
                                    <div className="d-flex">
                                      <strong className="pb-1">
                                        <u>Customer Info</u>
                                      </strong>
                                    </div>
                                    <div className="d-flex">
                                      <strong>
                                        {this.renderName(
                                          order.customer.firstName,
                                          order.customer.middleName,
                                          order.customer.lastName
                                        )}
                                      </strong>
                                      <span className="mx-2">|</span>{" "}
                                      <p className="m-0">
                                        {order.customer.mobileNumber}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="m-0">
                                        {order.customer.address}
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <p className="m-0 text-primary">
                                        <u>{order.customer.email}</u>
                                      </p>
                                    </div>
                                    <hr></hr>

                                    {order.itineraries.map((itinerary) => {
                                      return (
                                        <>
                                          <div className="d-flex flex-column">
                                            <div className="d-flex">
                                              <strong className="pb-1">
                                                <u>Itineraries</u>
                                              </strong>
                                            </div>
                                            <div className="d-flex">
                                              <strong>
                                                Purpose of Travel:{" "}
                                              </strong>
                                              <p className="ms-2 my-0">
                                                <u>{itinerary.purpose}</u>
                                              </p>
                                            </div>
                                            <div className="d-md-flex d-block mb-2">
                                              <div className="d-flex">
                                                <strong>Origin from: </strong>
                                                <p className="mx-2 mb-0">
                                                  <u>{`${itinerary.fromState} ,${itinerary.from}`}</u>
                                                </p>
                                              </div>
                                              <div className="d-flex">
                                                <strong className="my-0">
                                                  Destination to:
                                                </strong>
                                                <p className="ms-2 mb-0">
                                                  <u>{`${itinerary.toState} ,${itinerary.to}`}</u>
                                                </p>
                                              </div>
                                            </div>
                                            <div className="d-flex">
                                              <strong>Travel Period</strong>
                                            </div>
                                            <div className="d-flex">
                                              <strong className="m-0">
                                                Start:
                                              </strong>
                                              <p className="ms-2">
                                                <u>
                                                  {dateFormat(
                                                    new Date(
                                                      itinerary.startDate
                                                    )
                                                  )}
                                                </u>
                                              </p>
                                              <strong className="ms-2 my-0">
                                                End:
                                              </strong>
                                              <p className="ms-2">
                                                <u>
                                                  {dateFormat(
                                                    new Date(itinerary.endDate)
                                                  )}
                                                </u>
                                              </p>
                                            </div>
                                          </div>
                                          <hr></hr>
                                        </>
                                      );
                                    })}

                                    {/*<div className="d-flex">*/}
                                    {/*  <strong>*/}
                                    {/*    Total Days:{" "}*/}
                                    {/*    <span className="badge rounded-pill bg-success fs-xs">*/}
                                    {/*      {order.totalDays}*/}
                                    {/*    </span>*/}
                                    {/*  </strong>*/}
                                    {/*</div>*/}

                                    <div className="d-flex">
                                      <strong>
                                        Price:{" "}
                                        <span className="badge rounded-pill bg-success fs-xs">
                                          {formatCurrency(
                                            order.orderPrice,
                                            order.currency
                                          )}
                                        </span>
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    <div className="d-flex justify-content-between align-items-center m-2 bg-white">
                      <div className="p-2 px-lg-4">
                        <h6 className="m-0">Transaction ID: {product.id}</h6>
                        <p className="m-0">
                          Placed on {dateFormat(product.createdAt)}
                        </p>
                      </div>
                    </div>
                    <div className="m-2">
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-2">
                          <div className="d-flex flex-column bg-white p-2 p-lg-4">
                            <h5>{product.user.name}</h5>
                            <p className="lh-1 mb-1">{product.user.address}</p>
                            <p className="lh-1 mb-1 text-primary">
                              <u>{product.user.email}</u>
                            </p>
                            {/*<p>{order.customer.mobileNumber}</p>*/}
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <div className="d-flex flex-column bg-white p-2 p-lg-4 mb-3">
                            <h4>Total Amount</h4>
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Subtotal(1 item)</p>
                              <p className="m-0">
                                {product.payment &&
                                  formatCurrency(
                                    this.calculateTotalAmount(transactions),
                                    product.currency
                                  )}
                              </p>
                            </div>
                            <hr />
                            <div className="d-flex justify-content-between">
                              <p className="m-0">Total</p>
                              <p className="fs-5 h6">
                                {product.payment &&
                                  formatCurrency(
                                    this.calculateTotalAmount(transactions),
                                    product.currency
                                  )}
                              </p>
                            </div>
                            <p>
                              {/* {product?.payment?.status !== "PAID"
                                ? "Paid by: " +
                                  !product?.payment?.transactionType
                                  ? ""
                                  : product?.payment?.transactionType +
                                    "(Failed)"
                                : "Reference No: " + product.id} */}
                              {product?.payment?.status ? (
                                "Reference No: " + product.refNo
                              ) : (
                                <p style={{ color: "red" }}>
                                  This transaction has "Failed"
                                </p>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-grid d-md-flex mb-2 lh-1 align-items-center justify-content-md-end ">
                      <Button
                        onClick={this.goBack.bind(this)}
                        className="btn btn-primary btn-md px-lg-4 text-white"
                      >
                        GO BACK
                      </Button>
                      {/*{product.payment.paymentStatus !== "PAID" && (*/}
                      {/*        <Button*/}
                      {/*            onClick={this.payNow.bind(this)}*/}
                      {/*            className="btn btn-success btn-md px-lg-4 ms-3 text-white"*/}
                      {/*        >*/}
                      {/*          PAY NOW*/}
                      {/*        </Button>*/}
                      {/*    */}
                      {/*)}*/}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(OrderDetailsPage);
