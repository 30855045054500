import BasePage from "../../base/BasePage";
import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  InputString,
  InputNumber,
  InputDate,
  InputRadioGroup,
  InputSelect,
} from "nq-component";
import ProductFormPresenter from "./ProductFormPresenter";
import { findObjectUseCase, saveObjectUseCase } from "../../domain/object";
import { saveFileUseCase } from "../../domain/file";
// import InputFile from "./components/InputFile";
import withContext from "../../withContext";
import Beneficiary from "./components/Beneficiary/Beneficiary";
import dateDiff from "../../dateDiff";
import country from "../../country.json";
import InputFile from "../../components/InputFile";

class ProductFormPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      customer: {},
      name: {
        firstname: "initialValue",
        middlename: null,
        lastname: "initialValue",
      },
      mobile: undefined,
      nationality: undefined,
      beneficiaries: [{}],
      totalDays: 0,
      totalDays1: 0,
      totalAmount: 0,
      itineraries: [{ startDate: null, endDate: null, travelDays: 0 }],
      _itineraries: [{ startDate: null, endDate: null, travelDays: 0 }],
      isChecked: true,
      file: [],
      buttonCount: true,
      prices: {},
    };
    this.presenter = new ProductFormPresenter(
      this,
      saveObjectUseCase(),
      saveFileUseCase(),
      findObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.init();
  }

  addToCart() {
    this.presenter.addToCartClick(false);
  }

  proceedToCart() {
    this.presenter.addToCartClick(true);
  }

  getCustomer() {
    return this.state.customer;
  }

  getFile() {
    return this.state.file;
  }

  getBeneficiaries() {
    return this.state.beneficiaries;
  }

  getItineraries() {
    return this.state.itineraries;
  }

  getProduct() {
    return this.getArgument();
  }

  getCheckbox() {
    return this.state.isChecked;
  }

  getProductId() {
    return this.props.params.id;
  }

  getCount() {
    return this.state.count;
  }

  getTotalDays() {
    return this.state.totalDays;
  }

  getTotalAmount() {
    return this.state.totalAmount;
  }

  getCurrency() {
    return this.state.prices;
  }

  getDateToDay() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    return date;
  }

  onClickAddItinerary() {
    const itineraries = this.state.itineraries;
    itineraries.push({ id: this.presenter.makeid(5) });
    this.setState({ itineraries });
  }

  onClickAddBeneficiary() {
    const beneficiaries = this.state.beneficiaries;
    beneficiaries.push({ id: this.presenter.makeid(5) });
    this.setState({ beneficiaries });
  }

  onClickRemoveItinerary(index) {
    const itineraries = this.state.itineraries;
    itineraries.splice(index, 1);
    this.setState({ itineraries });
    this.setTotalDays();
  }

  onClickRemoveBeneficiary(index) {
    const beneficiaries = this.state.beneficiaries;
    beneficiaries.splice(index, 1);
    this.setState({ beneficiaries });
  }

  setItineraryError(index, _error) {
    this.setState((prevState) => {
      const updatedItineraries = prevState.itineraries.map(
        (itinerary, _index) => {
          if (_index === index) {
            return {
              ...itinerary,
              error: _error,
            };
          }
          return itinerary;
        }
      );

      return { itineraries: updatedItineraries };
    });
  }

  setItineraryStartError(index, _error) {
    this.setState((prevState) => {
      const updatedItineraries = prevState.itineraries.map(
        (itinerary, _index) => {
          if (_index === index) {
            return {
              ...itinerary,
              errorStart: _error,
            };
          }
          return itinerary;
        }
      );

      return { itineraries: updatedItineraries };
    });
  }

  setItineraryEndError(index, _error) {
    this.setState((prevState) => {
      const updatedItineraries = prevState.itineraries.map(
        (itinerary, _index) => {
          if (_index === index) {
            return {
              ...itinerary,
              errorEnd: _error,
            };
          }
          return itinerary;
        }
      );

      return { itineraries: updatedItineraries };
    });
  }

  clearItineraryStartError(index) {
    this.setState((prevState) => {
      const updatedItineraries = prevState.itineraries.map(
        (itinerary, _index) => {
          if (_index === index) {
            const { errorStart, ...updatedItinerary } = itinerary;
            return updatedItinerary;
          }
          return itinerary;
        }
      );

      return { itineraries: updatedItineraries };
    });
  }

  clearItineraryEndError(index) {
    this.setState((prevState) => {
      const updatedItineraries = prevState.itineraries.map(
        (itinerary, _index) => {
          if (_index === index) {
            const { errorEnd, ...updatedItinerary } = itinerary;
            return updatedItinerary;
          }
          return itinerary;
        }
      );

      return { itineraries: updatedItineraries };
    });
  }

  startDateOnInput(index, e) {
    const startDate = new Date(e.target.value);
    const itineraries = this.state.itineraries;
    // itineraries[index].startDate = this.setToStringDate(startDate);
    this.setTravelDays(index, startDate, itineraries[index].endDate);

    this.dateValidation(index, e, "startDate");
  }

  endDateOnInput(index, e) {
    const endDate = new Date(e.target.value);
    const itineraries = this.state.itineraries;
    // itineraries[index].endDate = this.setToStringDate(endDate);
    this.setTravelDays(index, itineraries[index].startDate, endDate);

    this.dateValidation(index, e, "endDate");
  }

  birthDateOnInput(e) {
    const birthDate = e.target.value;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");

    if (birthDate >= date) {
      this.showError("Invalid Birth Date: Future or Current Date");
      e.target.value = "";
    }
  }

  nameOnBlur(e, field) {
    const name = e.target.value;

    if (name.length < 2) {
      this.showError(`${field} must be at least 2 characters long`);
      return;
    }
  }

  dateValidation(index, e, validate) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");

    const totalDays = this.state.totalDays;
    const itineraries = this.state.itineraries;
    const startDate = itineraries[index].startDate;
    const endDate = itineraries[index].endDate;

    const checkDateOverlap = (start1, end1, start2, end2) => {
      return (
        (start1 <= end2 && start2 <= end1) ||
        (start1 >= start2 && start1 <= end2) ||
        (end1 >= start2 && end1 <= end2)
      );
    };

    const hasOverlap = itineraries.some((itinerary, i) => {
      if (i !== index) {
        return checkDateOverlap(
          startDate,
          endDate,
          itinerary.startDate,
          itinerary.endDate
        );
      }
      return false;
    });

    if (validate === "startDate") {
      if (startDate < date) {
        // this.showError("Travel period date must be higher than yesterday");
        this.setItineraryStartError(
          index,
          "Travel period date must be higher than today"
        );
        e.target.value = "";
      } else {
        this.clearItineraryStartError(index);
      }
    }

    if (startDate && endDate) {
      const dateCheck = dateDiff(
        this.setToDateFormat(startDate),
        this.setToDateFormat(endDate)
      );

      if (validate === "endDate") {
        if (dateCheck < 0) {
          // this.showError("End date must be higher than Start Date");
          this.setItineraryEndError(
            index,
            "End date must be higher than Start Date"
          );
          e.target.value = "";
        } else {
          this.clearItineraryEndError(index);
        }
      }

      if (totalDays > 365) {
        this.showError(
          "You have exceeded the maximum sum of itineraries' days"
        );
        e.target.value = "";
      }
    }

    if (hasOverlap) {
      // this.showError(
      //   "The selected date overlaps with an existing activity in another itinerary"
      // );
      this.clearItineraryEndError(index);
      this.setItineraryStartError(
        index,
        "The selected date overlaps with an existing activity in another itinerary"
      );
      e.target.value = "";
    }
  }

  setTravelDays(index, start, end) {
    const itineraries = this.state.itineraries;
    const startDate = start;
    const endDate = end;
    let total =
      dateDiff(this.setToDateFormat(startDate), this.setToDateFormat(endDate)) +
      1;

    if (startDate && endDate) {
      itineraries[index].travelDays = total;
      this.setTotalDays();
    }
  }

  setToDateFormat(date) {
    return new Date(date);
  }

  setTotalDays() {
    let totalDays;
    this.state.itineraries.map((itinerary) => {
      totalDays += itinerary.travelDays;
    });
    this.setState({ totalDays });
  }

  onCheckTerms() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  formSubmit(e) {
    e.preventDefault();
    this.state.buttonCount ? this.proceedToCart() : this.addToCart();
  }

  setPrices(prices) {
    this.setState({ prices });
  }

  setTotalDays() {
    let totalDays1 = 0;

    this.state.itineraries.map((itinerary) => {
      if (itinerary.travelDays) {
        totalDays1 += itinerary.travelDays;
        this.setState({ totalDays1 });
        this.state.totalDays = totalDays1;
      }
      this.setTotalAmount();
    });
  }
  setTotalAmount() {
    // if(this.state.totalDays)
    const totalAmount = this.state.prices.daysPrice[this.state.totalDays];
    this.state.totalAmount = totalAmount;
  }

  // fileSetState(collect) {
  //   this.setState({ file: collect });
  // }

  checkLength(state, minLength, maxLength) {
    if (state > minLength) {
      return false;
    }

    if (state < maxLength) {
      return false;
    }

    if (state === undefined) {
      return false;
    }
  }

  render() {
    const customer = this.state.customer;
    const beneficiaries = this.state.beneficiaries;

    const firstName = this.state.name.firstname;
    const lastName = this.state.name.lastname;

    const mobileNo = this.state.mobile;

    const nationality = this.state.nationality;

    const startDate = this.state.itineraries.startDate;
    const endDate = this.state.itineraries.endDate;
    const travelDays = this.state.itineraries.travelDays;
    let totalDays1 = this.state.totalDays1;
    const itineraries = this.state.itineraries;
    const progress = this.state.progress;
    const isChecked = this.state.isChecked;
    const file = this.state.file;

    console.log(mobileNo);

    return (
      <>
        <main
          className="container mainContent1 hidescroll mw-100 p-0 m-0 px-lg-5 "
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 mx-lg-5 ">
            <div className="container p-0 m-0 px-lg-5  ">
              <div className="px-lg-5 py-lg-5 mt-lg-3 p-3 mt-1 pt-4 mb-4 pb-lg-4 bg-white ">
                <div className="container-fluid">
                  <div className="d-flex container-fluid justify-content-center mb-lg-3 mb-3 ps-0 pe-0 me-0">
                    <div className="d-flex flex-column flex-fill">
                      <form
                        className="needs-validation"
                        noValidate=""
                        onSubmit={this.formSubmit.bind(this)}
                      >
                        <div className="container p-0 m-0 d-flex align-items-center">
                          <h4 className="ms-0 mb-4">APPLICATION FORM</h4>
                        </div>
                        <h5 className="p-0 m-0">
                          <i className="bi bi-people me-1"></i>CLIENT'S INFO
                        </h5>
                        <hr />
                        <small className="mb-2">
                          <strong>NAME OF ASSURED</strong>
                        </small>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className={`form-control ${
                              (firstName.length < 2 || firstName.length > 30) &&
                              firstName !== "initialValue" &&
                              "border-danger"
                            }`}
                            id="floatingInput"
                            placeholder="firstname"
                            field="firstName"
                            object={customer}
                            onChange={(event) =>
                              this.setState((prevState) => ({
                                name: {
                                  ...prevState.name,
                                  firstname: event.target.value,
                                },
                              }))
                            }
                          />
                          <small className="text-danger">
                            {firstName.length < 2 &&
                              firstName !== "initialValue" &&
                              "first name must be at least 2 characters long"}
                          </small>
                          <small className="text-danger">
                            {firstName.length > 30 &&
                              firstName !== "initialValue" &&
                              "first name must be not more than 30 characters long"}
                          </small>

                          <label for="floatingInput">
                            Firstname <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="middleName"
                            field="middleName"
                            object={customer}
                          />
                          <label for="floatingInput">Middlename</label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className={`form-control ${
                              (lastName.length < 2 || lastName.length > 30) &&
                              lastName !== "initialValue" &&
                              "border-danger"
                            }`}
                            id="floatingInput"
                            placeholder="lastName"
                            field="lastName"
                            object={customer}
                            onChange={(event) =>
                              this.setState((prevState) => ({
                                name: {
                                  ...prevState.name,
                                  lastname: event.target.value,
                                },
                              }))
                            }
                          />
                          <small className="text-danger">
                            {lastName.length < 2 &&
                              lastName !== "initialValue" &&
                              "last name must be at least 2 characters long"}
                          </small>
                          <small className="text-danger">
                            {lastName.length > 30 &&
                              lastName !== "initialValue" &&
                              "last name must be not more than 30 characters long"}
                          </small>
                          <label for="floatingInput">
                            Lastname <span className="text-danger">*</span>
                          </label>
                        </div>
                        <small className="mb-3">
                          <strong>
                            MAILING ADDRESS
                            <span className="text-danger">*</span>
                          </strong>
                        </small>
                        <div className="container p-0 m-0 d-flex d-none">
                          <div className="row p-2 flex-fill">
                            <div className="col-5 ps-1 pe-2 mt-2">
                              <div className="form-floating mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="House No."
                                />
                                <label for="floatingInput">House No.</label>
                              </div>
                            </div>
                            <div className="col-7 ps-2 pe-1 mt-2">
                              <div className="form-floating mb-2">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Street"
                                />
                                <label for="floatingInput">Street</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            field="address"
                            object={customer}
                          />
                          <label for="floatingInput">Complete Address</label>
                        </div>
                        <small>
                          <strong>CONTACT INFORMATION</strong>
                        </small>
                        <div className="form-floating mb-3 mt-3">
                          <InputString
                            required
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Email"
                            field="email"
                            object={customer}
                          />
                          <label htmlFor="floatingInput">
                            Email <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputNumber
                            required
                            type="text"
                            className={`form-control ${
                              mobileNo?.length > 12 &&
                              mobileNo !== undefined &&
                              "border-danger"
                            }`}
                            id="floatingInput"
                            field="mobileNumber"
                            object={customer}
                            onChange={(e) =>
                              this.setState({ mobile: e.target.value })
                            }
                          />
                          <small className="text-danger">
                            {mobileNo?.length > 12 &&
                              mobileNo !== undefined &&
                              "mobile number must be less than 12 characters"}
                          </small>
                          <label htmlFor="floatingInput">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputNumber
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            field="landlineNumber"
                            object={customer}
                          />
                          <label for="floatingInput">Telephone</label>
                        </div>
                        <small>
                          <strong>PERSONAL INFORMATION</strong>
                        </small>
                        <div className="container p-0 m-0 mt-3 d-flex justify-content-center">
                          <div className="row w-100">
                            <div className="col-12 pe-0 ps-0">
                              <div className="form-floating mb-3">
                                <InputDate
                                  required
                                  className="form-control"
                                  type="date"
                                  id="date"
                                  placeholder="Date"
                                  field="birthDate"
                                  object={customer}
                                  onChange={this.birthDateOnInput.bind(this)}
                                  onFocus={(e) => e.preventDefault()}
                                />
                                <label for="floatingInput">
                                  Birth Date
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container p-0 m-0 d-flex justify-content-center">
                          <div className="row w-100">
                            <div className="col-6 pe-0 ps-0">
                              <div className="form-floating mb-3">
                                <InputString
                                  required
                                  type="text"
                                  className={`form-control ${
                                    (nationality?.length < 2 ||
                                      nationality?.length > 30) &&
                                    nationality !== undefined &&
                                    "border-danger"
                                  }`}
                                  id="floatingInput"
                                  placeholder="Nationality"
                                  field="nationality"
                                  object={customer}
                                  onChange={(e) =>
                                    this.setState({
                                      nationality: e.target.value,
                                    })
                                  }
                                />
                                <small className="text-danger">
                                  {nationality?.length < 2 &&
                                    nationality !== undefined &&
                                    "nationality must be at least 2 characters long"}
                                </small>
                                <small className="text-danger">
                                  {nationality?.length > 30 &&
                                    nationality !== undefined &&
                                    "nationality must be not more than 30 characters long"}
                                </small>
                                <label for="floatingInput">
                                  Nationality
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-6 pe-0 mt-2  ps-lg-5">
                              <InputRadioGroup
                                required
                                name="gender"
                                document={customer}
                              />
                            </div>
                          </div>
                        </div>
                        <div className=" form-floating col-6 pe-0 ps-0 mb-4">
                          <InputSelect
                            required
                            options={[
                              "Single",
                              "Married",
                              "Divorced",
                              "Widowed",
                            ]}
                            field="civilStatus"
                            object={customer}
                          />
                          <label for="floatingInput">
                            Civil Status
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-4">
                          <InputString
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Occupation/Designation"
                            field="occupation"
                            object={customer}
                          />
                          <label for="floatingInput">
                            Occupation / Designation
                          </label>
                        </div>
                        {/* for beneficiaries */}
                        {beneficiaries.map((beneficiary, index) => {
                          return (
                            <React.Fragment
                              key={beneficiary._id || beneficiary.id}
                            >
                              <Beneficiary object={beneficiary} />
                              {beneficiaries.length > 1 && (
                                <>
                                  <div className="container p-0 m-0 d-flex mb-2">
                                    <div className="row flex-fill justify-content-between">
                                      <div className="col-7 col-lg-4 pe-0"></div>
                                      <div className="col-5 col-lg-4">
                                        <Button
                                          type="button"
                                          className="btn btn-danger text-white form-control mb-3"
                                          onClick={this.onClickRemoveBeneficiary.bind(
                                            this,
                                            index
                                          )}
                                        >
                                          <span className="d-flex justify-content-center align-items-center">
                                            <i className="bi bi-dash-circle lh-1 me-2"></i>
                                            REMOVE
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                        {beneficiaries.length !== 3 && (
                          <div className="container p-0 m-0 d-flex mb-2">
                            <div className="row flex-fill justify-content-between">
                              <div className="col-6 col-lg-4 pe-0">
                                <Button
                                  className="btn btn-success text-white form-control mb-3"
                                  type="button"
                                  onClick={this.onClickAddBeneficiary.bind(
                                    this
                                  )}
                                >
                                  <i className="bi bi-plus-circle me-2"></i>ADD
                                  BENEFICIARY
                                </Button>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* end of beneficiaries */}

                        {itineraries.map((itinerary, index) => {
                          return (
                            <React.Fragment key={itinerary.id}>
                              <div className="container p-0 m-0">
                                <small className="mt-2 mb-3">
                                  <strong>
                                    TRAVEL DETAILS{" "}
                                    <span className="text-danger">*</span>
                                  </strong>
                                </small>

                                <div className="col-12 mt-2">
                                  <InputSelect
                                    required
                                    className="mb-3"
                                    field="purpose"
                                    object={itinerary}
                                    options={[
                                      "Leisure",
                                      "Vacation",
                                      "Trip",
                                      "Tourism",
                                      "Training",
                                    ]}
                                  />
                                </div>

                                <small className="mb-2">
                                  <strong>
                                    ITINERARY{" "}
                                    <span className="text-danger">*</span>
                                  </strong>
                                </small>
                                <div className="container p-0 mt-2 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <small>
                                        <strong>
                                          <p className="mb-0">From</p>
                                        </strong>
                                      </small>
                                    </div>
                                    <div className="col-6 pe-0">
                                      <small>
                                        <strong>
                                          <p className="mb-0">To</p>
                                        </strong>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 mt-2 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputSelect
                                          required
                                          className="mb-3"
                                          field="from"
                                          object={itinerary}
                                          options={country.contryList}
                                        />
                                        <label htmlFor="floatingInput">
                                          Country
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputSelect
                                          required
                                          className="mb-3"
                                          field="to"
                                          object={itinerary}
                                          options={country.contryList}
                                        />
                                        <label htmlFor="floatingInput">
                                          Country
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputString
                                          required
                                          className="mb-3"
                                          field="fromState"
                                          object={itinerary}
                                        />
                                        <label htmlFor="floatingInput">
                                          State/City/Province
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputString
                                          required
                                          className="mb-3"
                                          field="toState"
                                          object={itinerary}
                                        />
                                        <label htmlFor="floatingInput">
                                          State/City/Province
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 m-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <small className="mb-2">
                                        <strong>
                                          TRAVEL PERIOD
                                          <span className="text-danger">*</span>
                                        </strong>
                                      </small>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                      <small className="mb-2">
                                        <strong className="d-flex align-items-end">
                                          <span>
                                            TRAVEL DAY/s:&nbsp;{" "}
                                            {itinerary.startDate &&
                                              itinerary.endDate &&
                                              dateDiff(
                                                new Date(itinerary.startDate),
                                                new Date(itinerary.endDate)
                                              ) + 1}
                                          </span>
                                          <span className="badge rounded-pill bg-success fs-sm"></span>
                                        </strong>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 m-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputDate
                                          required
                                          className={`form-control ${
                                            itineraries[index].errorStart &&
                                            "border-danger"
                                          }`}
                                          type="date"
                                          id="date1"
                                          field="startDate"
                                          onBlur={this.startDateOnInput.bind(
                                            this,
                                            index
                                          )}
                                          object={itinerary}
                                          min={this.getDateToDay()}
                                        />
                                        <small className="text-danger">
                                          {itineraries[index].errorStart}
                                        </small>
                                        <label htmlFor="floatingInput">
                                          Start
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputDate
                                          required
                                          className={`form-control ${
                                            itineraries[index].errorEnd &&
                                            "border-danger"
                                          }`}
                                          type="date"
                                          id="date2"
                                          field="endDate"
                                          onBlur={this.endDateOnInput.bind(
                                            this,
                                            index
                                          )}
                                          object={itinerary}
                                        />
                                        <small className="text-danger">
                                          {itineraries[index].errorEnd}
                                        </small>

                                        <label htmlFor="floatingInput">
                                          End
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {itineraries.length > 1 && (
                                <Button
                                  type="button"
                                  className="btn btn-danger text-white mb-3"
                                  onClick={this.onClickRemoveItinerary.bind(
                                    this,
                                    index
                                  )}
                                >
                                  <i className="bi bi-dash-circle me-2"></i>
                                  REMOVE
                                </Button>
                              )}
                            </React.Fragment>
                          );
                        })}

                        <p>
                          <strong>Total Days {totalDays1}</strong>
                          <br />
                          (maximum of 365 days)
                        </p>

                        {itineraries.length !== 3 && (
                          <div className="container p-0 m-0 d-flex mb-2">
                            <div className="row flex-fill justify-content-between">
                              <div className="col-6 col-lg-4 pe-0">
                                <Button
                                  className="btn btn-success text-white form-control mb-3"
                                  type="button"
                                  onClick={this.onClickAddItinerary.bind(this)}
                                >
                                  <i className="bi bi-plus-circle me-2"></i>ADD
                                  ITINERARY
                                </Button>
                              </div>
                              <div className="col-6 col-lg-4"></div>
                            </div>
                          </div>
                        )}

                        <div className="mb-5 mt-3">
                          <label className="form-label" for="customFile">
                            <strong>
                              Upload Documents (tickets, etc.)
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          {/* <input
                          type="file"
                          className="form-control mt-2"
                          id="customFile"
                          onChange={this.saveFile.bind(this)}
                          multiple
                        /> */}
                          <InputFile
                            className="form-control"
                            field="file"
                            object={file}
                            showError={this.showError.bind(this)}
                            accept={"image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt"}
                            // required
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <strong>DECLARATION</strong>
                          <small>
                            I hereby apply for a personal accident insurance and
                            declare and warrant that the statements and answers
                            are full, complete, and true and that I have not
                            withheld or concealed any information affecting this
                            proposed insurance. I agree that this proposal and
                            declaration shall be the basis of any policy to be
                            issued to me by the Company and that any concealment
                            or material misrepresentation shall render this
                            policy null and void.
                          </small>
                        </div>
                        <div className="form-check p-0 m-0 d-flex mb-2 mt-2 align-items-center">
                          <Checkbox
                            className="form-check p-0 m-0 d-flex mb-2 mt-2 align-items-center"
                            onChange={this.onCheckTerms.bind(this)}
                          />

                          <Checkbox
                            className="form-check p-0 m-0 d-flex mb-2 mt-2 align-items-center"
                            onChange={this.onCheckTerms.bind(this, isChecked)}
                          />
                          <label
                            className="form-check-label lh-1"
                            for="flexCheckDefault"
                          >
                            <div>
                              <small>
                                By submitting this form, you agree to our
                              </small>
                            </div>
                            <div>
                              <Link
                                className="text-success"
                                to="/term_and_conditionss.pdf"
                                target="_blank"
                              >
                                Terms and Conditions.
                              </Link>
                            </div>
                          </label>
                        </div>
                        <hr />
                        <div className="pt-lg-3 p-2"></div>
                        <div className="mt-lg-1 mb-0 ">
                          <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                            <Link
                              to="/"
                              className="btn btn-secondary btn-md me-lg-2 mb-1 text-white"
                            >
                              GO BACK
                            </Link>

                            <Button
                              progress={progress}
                              onClick={() => {
                                this.setState({ buttonCount: false });
                              }}
                              type="submit"
                              className="btn btn-primary btn-md me-lg-2 mb-1 text-white"
                              disabled={isChecked}
                            >
                              {progress ? "Please wait..." : "ADD TO CART"}
                            </Button>
                            <Button
                              progress={progress}
                              onClick={() => {
                                this.setState({ buttonCount: true });
                              }}
                              type="submit"
                              className="btn btn-success btn-md me-lg-2 mb-1 text-white"
                              disabled={isChecked}
                            >
                              {progress ? "Please wait..." : "BUY NOW"}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(ProductFormPage);
