class SignInPresenter {
  constructor(view, signInUseCase, findObjectUseCase, signOutUseCase) {
    this.view = view;
    this.signInUseCase = signInUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.signOutUseCase = signOutUseCase;
  }

  submit({ email, password }) {
    const user = {
      username: email,
      password,
    };
    const deactivatedErrorMsg =
      'We apologize for any inconvenience, but your account has been deactivated. Please contact us by email at "online@mici.com.ph" for further assistance.';
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.signInUseCase.execute(user))
      .then(async (user) => {
        if (user.deactivate) {
          try {
            await this.signOutUseCase.execute();
            this.view.hideProgress();
            this.view.showError(deactivatedErrorMsg);
          } catch (error) {
            this.view.hideProgress();
            this.view.showError(error);
          }
        } else {
          this.view.navigateTo("/");
          // alert("hi");
        }
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default SignInPresenter;
