import generateSignature from "../../generateSignature";

class PaymentReviewPresenter {
  constructor(
    view,
    saveObjectUseCase,
    deleteObjectUseCase,
    findObjectUseCase,
    makePaymentUseCase,
    upsertObjectUseCase
  ) {
    this.view = view;
    this.saveObjectUseCase = saveObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.makePaymentUseCase = makePaymentUseCase;
    this.upsertObjectUseCase = upsertObjectUseCase;
    this.pay = null;
  }

  init() {
    this.getData();
    this.getpay();
  }

  async getpay() {
    const response = await this.findObjectUseCase.execute("payments");
    console.log("haha", response);
  }

  getData() {
    const object = this.view.getArgument();
    if (object.id) {
      // const
    } else {
      this.cartItem = object;
      console.log("cart", this.cartItem);
      this.view.setOrders({ object });
    }
  }

  removeToCart() {
    this.cartItem.map((item) => {
      this.deleteObjectUseCase
        .execute("carts", item.id)
        .then(() => {
          const currentCartCount = this.view.getCurrentCartCount();
          // this.view.setCurrentCartCount(currentCartCount - 1);
          console.log(currentCartCount);
        })
        .catch((error) => {
          this.view.showError(error);
        });
    });
  }

  // removeToCart() {
  //   this.cartItem.map((item) => {
  //     // Fetch status from the transactions collection
  //     this.fetchStatusFromTransactions(item.id)
  //       .then((status) => {
  //         if (status === "paid") {
  //           return this.deleteObjectUseCase.execute("carts", item.id);
  //         } else if (status === "failed") {
  //           return null;
  //         } else {
  //           throw new Error("Item status is not paid");
  //         }
  //       })
  //       .then(() => {
  //         const currentCartCount = this.view.getCurrentCartCount();
  //         // this.view.setCurrentCartCount(currentCartCount - 1);
  //         console.log(currentCartCount);
  //       })
  //       .catch((error) => {
  //         this.view.showError(error);
  //       });
  //   });
  // }

  fetchStatusFromTransactions(itemId) {
    return this.findObjectUseCase
      .execute("transactions", { id: itemId })
      .then((transaction) => {
        // Assuming transaction object has a 'status' property
        return transaction.status;
      });
  }

  makeTransaction() {
    this.view.showProgressDialog();
    const object = this.view.getState();

    // return console.log(this.view.determinePaymentMethod(object.paymentMethod));
    const user = this.view.getCurrentUser();
    const email = user.email;

    const formatPrice = (item) => {
      const regex = /\B(?=(\d{3})+(?!\d))/g;
      if (item === null) {
        return "0";
      } else {
        return item.toString().replace(regex, ",");
      }
    };

    const formattedData = object.object?.map((item) => ({
      ...item,
      itineraries: item.itineraries.map((itinerary) => ({
        ...itinerary,
        startDate: new Date(itinerary.startDate).toLocaleDateString("en-US", {
          dateStyle: "medium",
        }),
        endDate: new Date(itinerary.endDate).toLocaleDateString("en-US", {
          dateStyle: "medium",
        }),
      })),
      product: {
        ...item.product,
        priceFormatted: formatPrice(item.product.price),
      },
      dateToday: new Date().toLocaleDateString("en-US", {
        dateStyle: "medium",
      }),
    }));

    const payment = {
      amount: object.totalAmount + "",
      user,
      email,
      firstname: user.firstName,
      middlename: user.middleName,
      lastname: user.lastName,
      details: formattedData,
      dateToday: new Date().toLocaleDateString("en-US", {
        dateStyle: "medium",
      }),
      // error: "test error",
      // productname: object[0].product.name,
      // productprice: object[0].product.price,
      // productdescription: object[0].product.description,
      // itestart: object[0].itineraries[0].startDate,
      // iteend: object[0].itineraries[0].endDate,
      transactionType: object.paymentMethod,
      currency: object.currency,
      transactionId: "0",
    };

    let orders = [];
    object.object.map((data) => {
      let itineraries = {};
      let beneficiaries = {};
      let file = {};
      let product = {};
      let customer = {};

      itineraries = data.itineraries;
      beneficiaries = data.beneficiaries;
      file = data.file;
      product = data.product;
      customer = data.customer;
      let index = [
        {
          itineraries,
          beneficiaries,
          file,
          product,
          customer,
          orderPrice: data.totalAmount,
          totalDays: data.totalDays,
        },
      ];
      index.map((index) => {
        orders.push(index);
      });
    });

    this.saveObjectUseCase
      .execute("payments", payment)
      .then((payment) => {
        const transactions = {
          user,
          payment,
          orders,
          // pmethod: this.view.determinePaymentMethod(object.paymentMethod),
          // pchannel: this.view.determinePaymentChannel(object.paymentMethod),
        };

        // const signature = generateSignature(transactions);
        // transactions.customerSignature = signature.customerSignature;
        // transactions.signature = signature.transactionSignature;
        this.pay = payment;
        transactions.redirect = "${response.Url}";
        transactions.refNo = "${response.RefNo}";
        console.log("RefNo", transactions.redirect);
        console.log("RefNo", "${response.RefNo}");
        return this.saveObjectUseCase.execute("transactions", transactions, {
          timeout: 1000 * 5 * 60,
        });
      })
      .then((transaction) => {
        console.log("then transaction", transaction);
        console.log("then transaction", transaction?.payment?.status);
        payment.transactionId = transaction.id;
        // payment.refno = "${response.RefNo}";
        console.log("transaction ID: ", payment.transactionId);

        if (this.pay?.id) {
          console.log("this.pay", this.pay?.id);
          const paymentUpdate = { ...this.pay };
          paymentUpdate.transactionId = transaction.id;
          paymentUpdate.refno = transaction.refNo;
          this.upsertObjectUseCase
            .execute("payments", paymentUpdate)
            .then((response) => {
              console.log("Upsert success:", response);
            })
            .catch((error) => {
              console.error("Upsert error:", error);
            });
          // this.removeToCart(this.cartItem);
          console.log("data");
          window.location.replace(transaction.redirect);
        }

        // return this.upsertObjectUseCase.execute("payments", );

        // return this.saveObjectUseCase.execute("payments", payment).then(() => {
        //   // After saving the payment object, execute the following actions
        //   this.removeToCart(this.cartItem);
        //   window.location.replace(transaction.redirect);
        // });

        // this.removeToCart(this.cartItem);
        // window.location.replace(transaction.redirect);
      })
      .catch((error) => {
        this.view.hideProgressDialog();
        this.view.hideProgress();
        this.view.showError(error);
        console.log(error);
      });

    // this.saveObjectUseCase.execute("transactions", transactions)
    //     .then((result) => {
    //         // const user = getName(result.user.name);
    //         //
    //         // const transaction = {
    //         //     id: result.id,
    //         //     orders: result.orders,
    //         //     paymentId: result.payment.id,
    //         //     //local
    //         //     // successRedirect: 'https://localhost:3000/transaction-history',
    //         //     // cancelRedirect: 'https://localhost:3000/transaction-history',
    //         //     //live
    //         //     successRedirect: 'https://mercantile.pwrdv.com/transaction-history',
    //         //     cancelRedirect: 'https://mercantile.pwrdv.com/transaction-history',
    //         //     method: 'onlinebanktransfer',
    //         //     channel: 'br_bdo_ph',
    //         //     amount: result.payment.paidAmount,
    //         //     firstName: user.firstName,
    //         //     lastName: user.lastName,
    //         //     middleName: user.middleName,
    //         //     email: result.user.email,
    //         //     phoneNumber: '12345678910',
    //         //     mobileNumber: '12345678910',
    //         // }
    //
    //         // this.makePaymentUseCase.execute(transaction)
    //         //     .then(result => {
    //         //         this.removeToCart(this.cartItem);
    //         //         window.location.replace(result.redirect);
    //         //     })
    //         //     .catch(err => {
    //         //         this.view.hideProgress();
    //         //         this.view.showError(err.message);
    //         //     })
    //     })
    //     .catch((error) => {
    //         this.view.hideProgress();
    //         this.view.showError(error);
    //     });
  }
}

export default PaymentReviewPresenter;
