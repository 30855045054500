class faqPresenter{
    constructor(view, findObjectUseCase) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
        this.faq = null;
    }
    componentDidMount() {
        this.view.showProgress();
        Promise.resolve()
            .then(() => this.getFaq())
            .then(() => this.view.hideProgress())
            .catch((error) => {
                this.view.hideProgress();
                this.view.showError(error);
            });
    }

    getFaq() {
        this.faq = this.view.getFaq();
        this.view.setFaq(this.faq);

        const query = {
            include: ["all"]
        };
        return this.findObjectUseCase
            .execute("frequently_ask_question", query)
            .then((faq) => {
                this.faq = faq;
                this.view.setFaq(this.faq);
            });

    }



}
export default faqPresenter;
