import {findObjectUseCase} from "../../domain/object";

class CartViewFormPresenter {
    constructor(view, findObjectUseCase) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
    }
    componentDidMount(){
        this.getData();
    }
    getData(){
        const id = this.view.getArgument();
        const query = {where: {id}, include: ["all"]};

        return this.findObjectUseCase.execute('carts', query)
            .then(([cartInfos])=>{
                this.cartInfo = cartInfos;
                console.log(cartInfos);
                this.view.setCartInfo(this.cartInfo)
                this.view.setBeneficiaries(this.cartInfo.beneficiaries);
                this.view.setItineraries(this.cartInfo.itineraries);
                this.view.setFiles(this.cartInfo.file);
            })
            .catch(error=>{
                this.view.showError(error);
            })
    }
}

export default CartViewFormPresenter;