import BasePage from "../../base/BasePage";
import {NavBar} from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import "./paymentSuccess.css";

class PaymentFailed extends BasePage {
    constructor(props) {
        super(props);
        this.state = {object : {}, paymentMethod: "Gcash"};
    }
    render() {
        const orders = this.state.object;
        const paymentMethod = this.state.paymentMethod;

        return (
            <>
                <main
                    className="container mainContent1 hidescroll d-flex flex-column flex-fill mw-100 p-0 m-0"
                    style={{ overflowY: "auto", overflowX: "hidden" }}
                >
                    <div
                        className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2 bg-white"
                        style={{ maxWidth: "800px" }}
                    >
                        <div className="container d-flex flex-column p-0 m-0">
                            <div className="d-flex flex-column mb-4 mt-2">
                                <div className="align-self-center mb-4">
                                    <i
                                        className="bi bi-x-circle text-danger lh-1"
                                        style={{ fontSize: "100px" }}
                                    ></i>
                                </div>
                                <div className="align-self-center">
                                    <h1 className="fw-bold display-6">Payment Failed</h1>
                                </div>
                                <div className="container-fluid p-0 d-flex justify-content-md-center mb-3">
                                    <h4 className="p-0 m-0 me-2">You failed to pay for your order.</h4>
                                </div>
                                <div className="container-fluid p-0 d-flex align-items-center mb-3">
                                    <h6 className="p-0 m-0 me-2">Your order has been removed from the cart and transferred into the transaction history.</h6>
                                </div>
                            </div>
                            <hr className="mb-4" />
                        </div>


                        <div className="mt-5 mb-0 ">
                            <div className="d-grid gap-1 d-md-flex justify-content-md-center">
                                <Link
                                    to="/transaction-history"
                                    className="btn btn-primary btn-md me-lg-2 mb-1 text-white"
                                >
                                    <span className="ps-3 pe-3">Go to Transaction History</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default PaymentFailed;
