import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import "./paymentSuccess.css";

class PaymentSuccess extends BasePage {
  constructor(props) {
    super(props);
    this.state = { object: {}, paymentMethod: "Gcash" };
  }
  render() {
    const orders = this.state.object;
    const paymentMethod = this.state.paymentMethod;

    return (
      <>
        <main
          className="container mainContent1 hidescroll d-flex flex-column flex-fill mw-100 p-0 m-0"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2 bg-white"
            style={{ maxWidth: "800px" }}
          >
            <div className="container d-flex flex-column p-0 m-0">
              <div className="d-flex flex-column mb-4 mt-2">
                <div className="align-self-center mb-4">
                  <i
                    className="bi bi-check-circle text-success lh-1"
                    style={{ fontSize: "100px" }}
                  ></i>
                </div>
                <div className="align-self-center">
                  <h1 className="fw-bold display-6">Payment Successful</h1>
                </div>
              </div>

              {/* <div className="container-fluid p-0 d-flex align-items-center mb-3">
                <h6 className="p-0 m-0 me-2">Reference:</h6>
                <span className="lh-1">1234567812358878</span>
              </div>

              <div className="container-fluid p-0 d-flex align-items-center">
                <h6 className="p-0 m-0 me-2">Date:</h6>
                <span className="lh-1">Sun, June 30, 2022</span>
              </div>
              <hr className="mb-4" /> */}
            </div>

            {/* <div className="product-services mb-2 px-2">
              <div className="card-ps d-flex bg-white align-items-center pt-lg-2 pb-lg-2 px-lg-3">
                <img
                  src={require("../../../src/assets/img/PlanA.jpeg")}
                  height="120"
                  width="120"
                  alt=""
                  className="m-2 me-1"
                  style={{ cursor: "pointer" }}
                />
                <div className="d-flex flex-column m-3 ms-1 me-1 w-100">
                  <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                    <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center text-nowrap">
                      <span>
                        {"PLAN A"}
                        <span className="text-success mx-1">{"₱500,000"}</span>
                      </span>

                      <span className="fs-6 mx-1 text-truncate">
                        {"Coverage"}
                      </span>
                    </h5>
                  </div>
                  <h6 className="mb-0 text-limit">
                    {
                      "For Domestic & International Travel Except Schengen Countries with Covid - 19 Insurance."
                    }
                  </h6>
                </div>
              </div>
            </div>

            <div className="px-lg-5 py-lg-3 mt-lg-3 p-3 mt-lg-2">
              <h6 className="mb-4 fw-bold">Order Summary</h6>
              <div className="d-flex justify-content-between">
                <p>Total Premium Products:</p>
                <p className="d-flex justify-content-end">1</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Payment Method:</p>
                <p className="d-flex justify-content-end">{paymentMethod}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Amount paid:</p>
                <p className="d-flex justify-content-end">PHP 857</p>
              </div>
              <hr />
            </div> */}

            <div className="mt-5 mb-0 ">
              <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                <Link
                  to="/"
                  className="btn btn-success btn-md me-lg-2 mb-1 text-white"
                >
                  <span className="ps-3 pe-3">DONE</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default PaymentSuccess;
