import changes from "../../changes";

class AccountPresenter {
  constructor(
    view,
    signInUseCase,
    findInformation,
    saveFileUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
    updateUserUseCase
  ) {
    this.view = view;
    this.signInUseCase = signInUseCase;
    this.findInformation = findInformation;
    this.saveFileUseCase = saveFileUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.updateUserUseCase = updateUserUseCase;
  }

  componentDidMount() {
    this.initUser();
  }

  initInformation() {
    const className = "users.information";
    const user = this.view.getCurrentUser();
    const query = { where: { user: { id: user.id } } };
    this.findInformation
      .execute(className, query)
      .then(([information]) => {
        this.view.hideProgress();
        if (information) {
          this.information = information;
          this.view.setInformation(Object.assign({}, information));
        } else {
          const info = { user };
          this.information = {};
          this.view.setInformation(info);
        }
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  initUser() {
    const user = this.view.getCurrentUser();
    this.user = user;
    this.view.setProfile(user.profile);
    this.view.setUser(Object.assign({}, user));
  }

  saveUserClick() {
    const user = this.view.getUser();

    this.updateUser(user);
  }

  // checkPasswordClick() {
  //   const user = this.view.getUser();
  //   this.user = user;
  //   console.log("User", user);
  //   if (user.oldPassword === "hello") {
  //     //   this.view.showSuccess("Correct");
  //     this.view.showSuccess("Password incorrect");
  //     const legit = true;
  //     this.view.setTrue(legit);
  //   } else {
  //     this.view.showError("Password incorrect");
  //   }
  // }
  validatePasswordForm(user) {
    const validation =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/;
    if (!user.oldPassword) {
      this.view.hideProgress();
      this.view.showError("Old Password is Required");
      return false;
    } else if (!user.newPassword || !user.confirmNewPassword) {
      this.view.hideProgress();
      this.view.showError("New Password is Required");
      return false;
    } else if (user.newPassword.length < 8 || user.newPassword.length > 16) {
      this.view.hideProgress();
      this.view.showError("Password length must be 8-16 characters.");
      return false;
    } else if (user.newPassword !== user.confirmNewPassword) {
      this.view.hideProgress();
      this.view.showError("New Password must be the same");
      return false;
    } else if (!validation.test(user.newPassword)) {
      this.view.hideProgress();
      this.view.showError(
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character."
      );
      return false;
    } else {
      return true;
    }
  }

  async savePasswordClick() {
    try {
      const user = this.view.getUser();
      const password = user.oldPassword;
      const username = user.username;

      this.view.showProgress();
      if (!this.validatePasswordForm(user)) return;

      const checkPass = {
        username,
        password,
      };

      console.log("Check", checkPass);

      await this.signInUseCase.execute(checkPass);

      const { newPassword } = user;
      delete user["newPassword"];
      delete user["confirmNewPassword"];

      user["password"] = newPassword;
      this.updateUser({ id: user.id, password: newPassword });
      this.view.showSuccess("Password updated successfully");
    } catch (error) {
      this.view.hideProgress();
      this.view.showError("Wrong Password");
    }
  }

  saveInformationClick() {
    const className = "users.information";
    const information = this.view.getInformation();
    this.view.showProgress();
    if (information.id) {
      const change = changes(this.information, information);
      change.id = this.information.id;
      this.updateObjectUseCase
        .execute(className, change)
        .then(() => {
          this.view.hideProgress();
          this.view.showSuccessSnackbar("Successfully saved!");
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    } else {
      this.saveObjectUseCase
        .execute(className, information)
        .then(() => {
          this.view.hideProgress();
          this.view.showSuccessSnackbar("Successfully saved!");
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  updateUser(user) {
    const change = changes(this.user, user);
    change.id = this.user.id;
    this.view.showProgress();
    this.updateUserUseCase
      .execute("users", change)
      .then((user) => {
        console.log(user);
        this.view.hideProgress();
        this.user = user;
        this.view.setCurrentUser(user);
        this.view.showSuccessSnackbar("Successfully saved!");
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  changeProfileClick(file) {
    this.view.showImageCropper(file);
  }

  onCrop(file) {
    this.saveFileUseCase.execute(file).then((response) => {
      const url = response.url;
      const user = { profile: url };
      this.updateUser(user);
    });
  }
}

// export default AccountPresenter;
export default AccountPresenter;
