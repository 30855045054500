import BasePage from "../../base/BasePage";
import {NavBar} from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import "./welcome.css";
// import MySvg1 from "../../assets/img/undraw_cooking_lyxy.svg";

class WelcomePage extends BasePage {
  render() {
    return (
      <>
        <div className="container d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            src={require("../../../src/assets/img/banner.png")}
            alt=""
            width="700"
            style={{ borderRadius: "20px" }}
          />
        </div>

        <div className="text-center">
          <h5>Get your insurance now</h5>
        </div>

        <div className="container d-flex  " style={{ maxWidth: "700px" }}>
          <div
            className="container-fluid d-flex justify-content-center m-0 p-0 mb-2 flex-wrap "
            style={{ maxWidth: "700px" }}
          >
            <Link
              to="/insurance"
              className="m-1 p-2 m-sm-2 p-sm-4 p-lg-3 m-lg-1 products bg-white"
              style={{ minWidth: "160px" }}
              gi
            >
              <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <img
                  src={
                    require("../../../src/assets/img/travel_wlcm.svg").default
                  }
                  alt=""
                  srcset=""
                  height="60px"
                />
                <h6 className="m-0 p-0">
                  <small>Travel Insurance</small>
                </h6>
              </div>
            </Link>
            <Link
              to="/insurance"
              className="m-1 p-2 m-sm-2 p-sm-4 p-lg-3 m-lg-1  products bg-white"
              style={{ minWidth: "160px" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <img
                  src={require("../../../src/assets/img/life_wlcm.svg").default}
                  alt=""
                  srcset=""
                  height="60px"
                />
                <h6 className="m-0 p-0">
                  <small>Life Insurance</small>
                </h6>
              </div>
            </Link>
            <Link
              to="/insurance"
              className="m-1 p-2 m-sm-2 p-sm-4 p-lg-3 m-lg-1 products bg-white"
              style={{ minWidth: "160px" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <img
                  src={require("../../../src/assets/img/car_wlcm.svg").default}
                  alt=""
                  srcset=""
                  height="60px"
                />
                <h6 className="m-0 p-0">
                  <small>Car Insurance</small>
                </h6>
              </div>
            </Link>
            <Link
              to="/insurance"
              className="m-1 p-2 m-sm-2 p-sm-4 p-lg-3 m-lg-1 products bg-white"
              style={{ minWidth: "160px" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center pt-2">
                <img
                  src={
                    require("../../../src/assets/img/house_wlcm.svg").default
                  }
                  alt=""
                  srcset=""
                  height="60px"
                />
                <h6 className="m-0 p-0">
                  <small>House Insurance</small>
                </h6>
              </div>
            </Link>
          </div>
        </div>

        {/* <div className="greetings container d-flex justify-content-center align-items-center mb-3 mt-lg-3 mt-0">
          <div
            className="container d-flex flex-column justify-content-center align-items-center text-white"
            style={{
              textShadow: "2px 2px rgba(0, 0, 0, 0.15)",
              height: "200px",
            }}
          >
            <h4 className="text-center">Hello, {user.name}</h4>
            <h5 className="text-center">
              Here at Mercantile Insurance, we know you have worked tirelessly
              for the future you deserve.
            </h5>
          </div>
        </div> */}

        <div className="d-flex justify-content-center ms-lg-0 me-lg-0 ms-3 me-3 ">
          <div
            className="container d-flex flex-column justify-content-center align-items-center mt-4 pt-3 pb-3 mb-5 bg-white"
            style={{
              maxWidth: "700px",
              backgroundColor: "rgb(231, 231, 231)",
              borderRadius: "10px",
            }}
          >
            <Link to="#" className="d-flex justify-content-center">
              <small className="mb-1 text-success">
                <strong>Treat yourself with Scrumptious Delights!</strong>
              </small>
            </Link>
            <small className="placeholder-xs lh-2 text-center fs-xs">
              Get ₱100 OFF and FREE BASE DELIVERY with Link minimum purchase of
              ₱800 from all participating MGI brands (Yellow Cab, Pancake House
              and Krispy Kreme) within ordermo service areas until June 30!
            </small>
          </div>
        </div>
      </>
    );
  }
}

export default WelcomePage;
