import { Button } from "nq-component";
import React, { Component } from "react";
import BasePage from "../../base/BasePage";
import "./HomePage.css";

export default class HomePage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: true,
    };
    this.toastRef = React.createRef();
  }

  // componentDidMount() {
  //   const hasShownDialog = sessionStorage.getItem("hasShownDialog");
  //   if (hasShownDialog) {
  //     this.setState({ showDialog: false });
  //   }

  //   window.addEventListener("beforeunload", this.handleBeforeUnload);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("beforeunload", this.handleBeforeUnload);
  // }

  // handleBeforeUnload = () => {
  //   sessionStorage.setItem("hasShownDialog", true);
  // };

  // handleClose = () => {
  //   this.setState({ showDialog: false });
  //   sessionStorage.setItem("hasShownDialog", true);
  // };

  componentDidMount() {
    const hasShownDialog = sessionStorage.getItem("hasShownDialog");
    if (hasShownDialog) {
      this.setState({ showDialog: false });
    }
    window.addEventListener("beforeunload", this.handleBeforeUnload);

    // Initialize the toast using Bootstrap's JavaScript
    if (this.state.showDialog) {
      this.showToast();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = () => {
    sessionStorage.setItem("hasShownDialog", true);
  };

  handleClose = () => {
    this.setState({ showDialog: false });
    sessionStorage.setItem("hasShownDialog", true);
  };

  showToast = () => {
    const toastElement = this.toastRef.current;
    if (toastElement) {
      const toast = new window.bootstrap.Toast(toastElement, {
        autohide: false,
      });
      toast.show();
    }
  };

  // componentDidMount() {
  //   const hasShownDialog = sessionStorage.getItem("hasShownDialog");
  //   if (hasShownDialog) {
  //     this.setState({ showDialog: false });
  //   }

  //   window.addEventListener("beforeunload", this.handleBeforeUnload);
  //   if(this.state.showDialog){
  //     this.showToast()
  //   }
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("beforeunload", this.handleBeforeUnload);
  // }

  // handleBeforeUnload = () => {
  //   sessionStorage.setItem("hasShownDialog", true);
  // };

  // handleClose = () => {
  //   this.setState({ showDialog: false });
  //   sessionStorage.setItem("hasShownDialog", true);
  // };

  onNavigateToProducts(e) {
    e.preventDefault();
    this.navigateTo("/products");
  }

  render() {
    const user = this.getCurrentUser();
    const { showDialog } = this.state;

    return (
      <main
        className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
        style={{ overflowY: "auto", overflowX: "hidden" }}
      >
        <div className="d-flex mt-3 mx-3 mx-md-0">
          <div className="d-flex fs-2 align-items-center">
            <img
              src={user.profile}
              style={{ width: "50px", height: "50px", borderRadius: "50%" }}
            />
          </div>
          <div className="d-flex align-items-center">
            <h4 className="mx-3 my-0">{`HELLO, ${
              user?.firstName?.toUpperCase() || ""
            } ${user?.lastName?.toUpperCase() || ""}`}</h4>
          </div>
        </div>

        <div
          className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 mt-1 justify-content-center"
          style={{ maxWidth: "900px" }}
        >
          <strong>
            <h1 className="text-center mt-5 homepage-heading">Travel </h1>
          </strong>
          <strong>
            <h1 className="text-center mt-0 homepage-heading">Insurance</h1>
          </strong>
          <p className="text-center homepage-body">
            Mweeb offers comprehensive travel insurance coverage to protect
            travelers against various risks and uncertainties. Our policy
            includes trip cancellation and interruption coverage, emergency
            medical expenses, baggage and personal belongings coverage, travel
            delay and missed connection protection, personal liability coverage,
            and 24/7 emergency assistance. With our reliable and professional
            services, travelers can explore the world with confidence, knowing
            they are financially protected.
          </p>

          <div className="grid">
            <div className="row justify-content-center">
              <div className="d-grid col flex justify-content-center mt-3">
                <button
                  onClick={this.onNavigateToProducts.bind(this)}
                  className="homepage-button py-1 px-3"
                >
                  CHOOSE YOUR PLAN NOW
                </button>
              </div>
            </div>
          </div>

          {/* <p className="my-2 ms-2 mb-4">
                Mercantile Insurance Building, General Luna St. corner
                Beaterio St. Intramuros, Manila, Philippines, 1002
                <br />
                Contact Number : (632) 8527-7701 to 20
                <br />
                Email Address : online@mici.com.ph
              </p> */}
        </div>
        <div className="grid mt-lg-4 mb-lg-4">
          <div className="row align-items-center justify-content-between mx-3 mx-sm-0">
            <div className="col-md-5 d-flex align-items-center mt-4">
              <div className="d-flex fs-2 align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-house-door-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z" />
                </svg>
              </div>
              <div className="d-flex px-2 align-items-center">
                <p className="m-0">
                  {" "}
                  402 De oro Bldg, St Sierra Madre Mandaluyong City
                </p>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center justify-content-md-center mt-4">
              <div className="d-flex fs-2 align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-telephone-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                  />
                </svg>
              </div>
              <div className="d-flex px-2 align-items-center">
                <p className="m-0 text-center">(632) 7577-7701 to 20</p>
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-md-end mt-4">
              <div className="d-flex fs-2 align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-envelope-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                </svg>
              </div>
              <div className="d-flex px-2 align-items-center">
                <p className="m-0">sample@mweeb.com</p>
              </div>
            </div>
            {/* {showDialog && (
              <div className="card">
                <p>
                  TO OFFER YOU A BETTER EXPERIENCE, THIS SITE USES COOKIES,
                  INCLUDING THOSE OF THIRD PARTIES. TO FIND OUT MORE, CONSULT
                  THE PRIVACY POLICY " I ACCEPT"
                </p>
                <button onClick={this.handleClose}>Close</button>
              </div>
            )} */}
            <div>
              {showDialog && (
                <div
                  className="position-fixed bottom-0 end-0 p-3"
                  style={{ zIndex: 11 }}
                >
                  <div
                    className="toast align-items-center"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    ref={this.toastRef}
                    style={{ width: "270px" }}
                  >
                    <div className="toast-header">
                      <strong className="me-auto">Cookie Policy</strong>
                      {/* <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={this.handleClose}
                      ></button> */}
                    </div>
                    <div className="toast-body " style={{ fontSize: "10px" }}>
                      To offer you a better experience, this site uses cookie,
                      including those of third parties. To find out more,
                      consult the privacy policy
                      <div className="mt-2 pt-2 border-top text-center">
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={this.handleClose}
                        >
                          I ACCEPT
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    );
  }
}
