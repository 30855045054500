import React from "react";
import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import { ImageCropper } from "nq-component";
import { dialog } from "nq-component";
import AccountPresenter from "./AccountPresenter";
import browseFile from "../../browseFile";
import canvasToBlob from "../../canvasToBlob";
import { saveFileUseCase } from "../../domain/file";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import InputInfo from "./InputInfo";
import password from "./password.json";
import passwordOld from "./passwordOld.json";
import PasswordForm from "./PasswordForm";
import getProfile from "../../getProfile";
import generateProfile from "../../generateProfile";
import { signInUseCase } from "../../domain/user";

class AccountPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      user: undefined,
      profile: undefined,
      information: {},
      legit: false,
    };
    this.imageCropper = React.createRef();
    this.presenter = new AccountPresenter(
      this,
      signInUseCase(),
      findObjectUseCase(),
      saveFileUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase(),
      updateObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  formSubmit(e) {
    e.preventDefault();
  }

  changeProfileClick() {
    browseFile("image/*").then((files) => {
      if (files.length > 0) {
        const file = files[0];
        this.presenter.changeProfileClick(file);
      }
    });
  }

  setProfile(profile) {
    this.setState({ profile });
  }

  showImageCropper(file) {
    dialog.fire({
      html: (
        <ImageCropper
          ref={this.imageCropper}
          className='w-100'
          src={file}
          width={500}
          height={500}
          border={50}
          borderRadius={250}
        />
      ),
      positiveButton: "SAVE",
      onPositiveClick: () => {
        const cropper = this.imageCropper.current;
        const canvas = cropper.getCrop();
        const dataUrl = canvas.toDataURL();
        this.setState({ profile: dataUrl });
        canvasToBlob(canvas, file.name).then((blob) =>
          this.presenter.onCrop(blob)
        );
      },
    });
  }

  saveUserClick() {
    this.presenter.saveUserClick();
  }

  savePasswordClick() {
    this.presenter.savePasswordClick();
  }

  checkOldPasswordClick() {
    this.presenter.checkPasswordClick();
  }

  saveInformationClick() {
    this.presenter.saveInformationClick();
  }

  setUser(user) {
    this.setState({ user });
  }

  getUser() {
    return this.state.user;
  }

  getInformation() {
    return this.state.information;
  }

  setInformation(information) {
    this.setState({ information });
  }

  setTrue(legit) {
    this.setState({ legit });
  }

  render() {
    const user = this.state.user;
    const legit = this.state.legit;

    if (!user) return null;
    const name = user.name || user.username;
    const profile = this.state.profile || generateProfile(user.username);
    return (
      <>
        <div className='container-fluid mainContent1 hidescroll mw-100 mt-3'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='bg-white p-3 shadow-sm'>
                <div className='position-relative'>
                  <button
                    onClick={this.changeProfileClick.bind(this)}
                    className='btn btn-light bg-white btn-sm text-muted rounded-circle position-absolute shadow-sm'
                    style={{ left: "50%", transform: "translate(100%, 200%)" }}
                  >
                    <i className='bi bi-camera'></i>
                  </button>
                </div>
                <div className='text-center'>
                  <img
                    alt='profile'
                    className='img-fluid rounded-circle img-thumbnail m-2'
                    src={profile}
                    width='100'
                    height='100'
                  />
                  <p className='m-0 fs-sm fw-bold'>{name}</p>
                </div>
                <hr />
              </div>
              <div className='mt-3 mt-md-0'>
                <div className='bg-white p-3 shadow-sm'>
                  <span className='text-muted fs-sm'>User Information</span>
                  <ul className='list-group list-group-flush'>
                    {/* <InputInfo
                      icon="bi bi-card-checklist"
                      field="name"
                      object={user}
                      label={"Full Name: "}
                      placeholder="fist-name last-name"
                      onSave={this.saveUserClick.bind(this)}
                    /> */}
                    <InputInfo
                      icon='bi bi-card-checklist'
                      field='firstName'
                      object={user}
                      label={"First Name:"}
                      placeholder='first-name'
                      onSave={this.saveUserClick.bind(this)}
                    />
                    <InputInfo
                      icon='bi bi-card-checklist'
                      field='lastName'
                      object={user}
                      label={"Last Name: "}
                      placeholder='last-name'
                      onSave={this.saveUserClick.bind(this)}
                    />
                    <InputInfo
                      icon='bi bi-envelope'
                      field='username'
                      object={user}
                      label={"Email: "}
                      onSave={this.saveUserClick.bind(this)}
                    />
                    <PasswordForm
                      fields={password}
                      fieldss={passwordOld}
                      object={user}
                      onCheck={this.checkOldPasswordClick.bind(this)}
                      onSave={this.savePasswordClick.bind(this)}
                      tru={legit}
                    />
                    <InputInfo
                      type='Date'
                      icon='bi bi-link-45deg'
                      field='createdAt'
                      object={user}
                      label='Joined: '
                      edit={false}
                      onSave={this.saveUserClick.bind(this)}
                    />

                    <InputInfo
                      icon='bi bi-link-45deg'
                      field='id'
                      object={user}
                      label='ID: '
                      edit={false}
                      onSave={this.saveUserClick.bind(this)}
                    />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountPage;
