import React from "react";
import { Link } from "react-router-dom";
import {NavBar} from "nq-component";
import Img1 from "../../assets/img/under-construction.svg";

const NotFoundPage = () => {
  return (
    <>
      <main className="container mainContent1 hidescroll mw-100 px-lg-5 m-0 p-0">
        <div className="px-lg-5 mt-5 m-5 px-lg-5 m-0 p-0">
          <div className="px-lg-5 mx-lg-5 m-0 p-0">
            <div className="text-center">
              <img
                className="img-fluid login-img"
                width="500"
                src={Img1}
                alt="banner"
              />
              <h1 className="display-1 fw-bold">404</h1>
              <p className="lead">The page you’re looking for doesn’t exist.</p>
              <Link to="/" className="btn btn-primary">
                Go Home
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
