class HowToClaimPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.current = 1;
    this.where = {};
    this.howToClaim = null;
    this.progress = false;
  }

  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getData())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getData() {
    const query = {
      include: ["all"],
    };

    Promise.resolve().then(() => {
      this.findObjectUseCase
        .execute("how_to_claim", query)
        .then((howToClaim) => {
          this.howToClaim = howToClaim;
          // console.log(this.howToClaim)
          this.view.setHowToClaim(this.howToClaim);
        });
    });
  }
}

export default HowToClaimPresenter;
