import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import { Button } from "nq-component";
import { Link } from "react-router-dom";
import "./paymentReview.css";
import withContext from "../../withContext";
import formatCurrency from "../../formatCurrency";
import dateFormat from "../../dateFormat";
import PaymentReviewPresenter from "./paymentReviewPresenter";
import CustomInputRadioGroup from "../../components/CustomInputRadioGroup";
import {
  deleteObjectUseCase,
  saveObjectUseCase,
  findObjectUseCase,
  upsertObjectUseCase,
} from "../../domain/object";
import { makePaymentUseCase } from "../../domain/payment";

class PaymentReview extends BasePage {
  constructor(props) {
    super(props);
    this.state = { object: [], totalAmount: 0, currency: "PHP" };
    this.presenter = new PaymentReviewPresenter(
      this,
      saveObjectUseCase(),
      deleteObjectUseCase(),
      findObjectUseCase(),
      makePaymentUseCase(),
      upsertObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.init();
  }

  goBack() {
    this.navigateBack();
  }

  setOrders(object) {
    this.setState({ object: object.object });
  }

  getState() {
    return this.state;
  }

  confirmOrder(e) {
    e.preventDefault();
    this.getTotalAmount();
    this.presenter.makeTransaction();
  }

  getTotalAmount() {
    let totalAmount = 0;
    const orders = this.state.object;
    orders.map((order) => {
      totalAmount += order.totalAmount;
    });
    this.state.totalAmount = totalAmount;
  }

  determinePaymentMethod(method) {
    switch (method) {
      case "BDO":
      case "BPI":
        return "onlinebanktransfer";
      case "Bancnet":
        return "creditcard";
      case "Maya":
      case "Gcash":
        return "wallet";
      default:
        return "";
    }
  }

  determinePaymentChannel(channel) {
    switch (channel) {
      case "BDO":
        return "br_bdo_ph";
      case "BPI":
        return "bpi_ph";
      case "Bancnet":
        return "bancnet_cc";
      case "Paymaya":
        return "paymaya_ph";
      case "Gcash":
        return "gc";
      default:
        return "";
    }
  }

  renderName(firstName, middleName, lastName) {
    const firstname = firstName === undefined ? "" : firstName;
    const middlename = middleName === undefined ? "" : middleName;
    const lastname = lastName === undefined ? "" : lastName;
    return `${firstname} ${middlename} ${lastname}`;
  }

  render() {
    const orders = this.state.object;

    const paymentMethod = this.state;
    const progress = this.state.progress;
    let totalAmount = this.state.totalAmount;
    let currency = this.state.currency;

    return (
      <>
        <main
          className="container mainContent1 hidescroll d-flex flex-column flex-fill mw-100 p-0 m-0"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2 bg-white"
            style={{ maxWidth: "800px" }}
          >
            <h4 className="mb-4">Review Order Details</h4>

            {orders.map((orders, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="accordion mb-3 product-services"
                    id={`accord${index}`}
                  >
                    <div className="accordion-item card-ps mb-3">
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className="accordion-button bg-white py-2 "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse${index}`}
                          style={{ borderRadius: "20px" }}
                        >
                          <div className=" d-flex bg-white align-items-center">
                            <img
                              src={orders.product.image}
                              height="120"
                              width="120"
                              alt=""
                              className="m-2 me-1"
                              style={{ cursor: "pointer" }}
                            />
                            <div className="d-flex flex-column m-3 ms-1 me-1 w-100">
                              <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                                <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center">
                                  <span className="text-truncate">
                                    {orders.product.name}
                                    <span className="text-success mx-1 text-truncate">
                                      {formatCurrency(orders.product.price)}
                                    </span>
                                  </span>

                                  <span className="fs-6 mx-1 text-truncate">
                                    {"Coverage"}
                                  </span>
                                </h5>
                              </div>
                              <h6 className="mb-0 text-limit">
                                {orders.product.description}
                              </h6>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className="accordion-collapse collapse show"
                        aria-labelledby={`heading${index}`}
                        data-bs-parent={`accord${index}`}
                      >
                        <div className="accordion-body">
                          <div className="d-flex flex-column">
                            <div className="d-flex">
                              <strong className="pb-1">
                                <u>Customer Info</u>
                              </strong>
                            </div>
                            <div className="d-flex">
                              <strong>
                                {this.renderName(
                                  orders.customer.firstName,
                                  orders.customer.middleName,
                                  orders.customer.lastName
                                )}
                              </strong>
                              <span className="mx-2">|</span>{" "}
                              <p className="m-0">
                                {orders.customer.mobileNumber}
                              </p>
                            </div>
                            <div className="d-flex">
                              <p className="m-0">{orders.customer.address}</p>
                            </div>
                            <div className="d-flex">
                              <p className="m-0 text-primary">
                                <u>{orders.customer.email}</u>
                              </p>
                            </div>
                          </div>
                          <hr></hr>
                          {orders.itineraries.map((itinerary) => {
                            return (
                              <>
                                <div className="d-flex flex-column" key={index}>
                                  <div className="d-flex">
                                    <strong className="pb-1">
                                      <u>Itenenaries</u>
                                    </strong>
                                  </div>
                                  <div className="d-flex">
                                    <strong>Purpose of Travel: </strong>
                                    <p className="ms-2 my-0">
                                      <u>{itinerary.purpose}</u>
                                    </p>
                                  </div>
                                  <div className="d-md-flex d-block mb-2">
                                    <div className="d-flex">
                                      <strong>Origin from: </strong>
                                      <p className="mx-2 mb-0">
                                        <u>{`${itinerary.fromState} ,${itinerary.from}`}</u>
                                      </p>
                                    </div>
                                    <div className="d-flex">
                                      <strong className="my-0">
                                        Destination to:
                                      </strong>
                                      <p className="ms-2 mb-0">
                                        <u>{`${itinerary.toState} ,${itinerary.to}`}</u>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex">
                                    <strong>Travel Period</strong>
                                  </div>
                                  <div className="d-flex">
                                    <strong className="m-0">Start:</strong>
                                    <p className="ms-2">
                                      <u>
                                        {dateFormat(
                                          new Date(itinerary.startDate)
                                        )}
                                      </u>
                                    </p>
                                    <strong className="ms-2 my-0">End:</strong>
                                    <p className="ms-2">
                                      <u>
                                        {dateFormat(
                                          new Date(itinerary.endDate)
                                        )}
                                      </u>
                                    </p>
                                  </div>
                                </div>
                                <hr></hr>
                              </>
                            );
                          })}
                          <div className="d-flex">
                            <strong>
                              Total Days:{" "}
                              <span className="badge rounded-pill bg-success fs-xs">
                                {orders.totalDays}
                              </span>
                            </strong>
                          </div>
                          <div className="d-flex">
                            <strong>
                              Price:{" "}
                              <span className="badge rounded-pill bg-success fs-xs">
                                {formatCurrency(
                                  orders.totalAmount,
                                  orders.currency
                                )}
                              </span>
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="py-lg-3 mt-lg-3 p-3 px-lg-0 mt-lg-2">
              <div className="d-flex justify-content-between">
                <p>Total Premium Products: </p>
                <p className="d-flex justify-content-end">{orders.length}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>Amount to be paid:</p>
                <p className="d-flex justify-content-end">
                  {orders.map((order) => {
                    totalAmount += order.totalAmount;
                    currency = order.currency;
                  })}
                  {formatCurrency(totalAmount, currency)}
                </p>
              </div>
              <hr />
            </div>
            <form onSubmit={this.confirmOrder.bind(this)}>
              {/* <CustomInputRadioGroup
                required
                category='Payment Method'
                options={[
                  {
                    label: 'BDO Direct Debit (Savings and Current)',
                    option: 'BDO Direct Debit (Savings and Current)',
                    icon: 'bi bi-credit-card-fill',
                  },
                  {
                    label: 'BPI Direct Debit (Savings and Current)',
                    option: 'BPI',
                    icon: 'bi bi-cash-stack',
                  },
                  // {
                  //   option: "Bancnet",
                  //   icon: "bi bi-cash-stack",
                  // },
                  {
                    label: 'Maya',
                    option: 'Maya',
                    icon: 'bi bi-cash-stack',
                  },
                  {
                    label: 'Gcash',
                    option: 'Gcash',
                    icon: 'bi bi-cash-stack',
                  },
                ]}
                // icons={[
                //   "bi bi-credit-card-fill",
                //   "bi bi-paypal",
                //   "bi bi-cash-stack",
                // ]}
                object={paymentMethod}
                field='paymentMethod'
                // className="form-check-input ms-2 me-3"
              /> */}
              <div className="mt-5 mb-0 ">
                <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                  <div
                    onClick={this.goBack.bind(this)}
                    className="btn btn-primary btn-md me-lg-2 mb-1 text-white"
                    role="button"
                  >
                    {progress ? "Please wait..." : "GO BACK"}
                  </div>
                  <Button
                    progress={progress}
                    type="submit"
                    className="btn btn-success btn-md me-lg-2 mb-1 text-white"
                  >
                    <span className="ps-3 pe-3">
                      {progress ? "Please wait..." : "CONFIRM"}
                    </span>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(PaymentReview);
