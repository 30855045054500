import BasePage from "../../base/BasePage";
import React from "react";
import HowToClaimPresenter from "./HowToClaimPresenter";
import { findObjectUseCase } from "../../domain/object";
import withContext from "../../withContext";
import Image from "../../assets/img/How-To-Claim.jpg";
import file from "../../files/claim_form/Notification of Claim Form.pdf";

class HowToClaimPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new HowToClaimPresenter(this, findObjectUseCase());
    this.state = { howToClaim: [] };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setHowToClaim(howToClaim) {
    this.setState({ howToClaim });
  }

  render() {
    const howToClaim = this.state.howToClaim;

    return (
      <>
        <main
          className='container mainContent1 hidescroll mw-100  m-0 p-0 px-lg-5'
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className='px-lg-5 mt-5 mb-5 p-0'>
            <div className='px-lg-5 mx-lg-5 m-0 p-0'>
              <div className='container bg-white'>
                <div className='h-100 d-flex align-items-center'>
                  <div className='text-center w-100 px-lg-5'>
                    <img
                      className='img-fluid login-img mb-4 mt-5'
                      width='250'
                      src={Image}
                    />
                    <div className='text-center w-100'>
                      <h4 className='mb-3'>
                        <strong>Steps in filing PA travel claims</strong>
                      </h4>
                      <section className='mb-5 px-lg-5 mx-2 text-start'>
                        {howToClaim.map((howToClaimData, id) => {
                          return (
                            <div>
                              {howToClaimData?._details
                                ?.split("\n")
                                .map((details) => {
                                  if (/^\s*\d+\./.test(details)) {
                                    return (
                                      <p>
                                        <b>{details}</b>
                                      </p>
                                    );
                                  }

                                  return <p>{details}</p>;
                                })}
                            </div>
                          );
                        })}
                      </section>
                    </div>
                    <h4 className='mb-3'>
                      <strong>
                        List of Claims Documents Required (When filing a Travel
                        PA Insurance Claim)
                      </strong>
                    </h4>
                  </div>
                </div>
                <section className='mb-5 px-lg-5 mx-2'>
                  {howToClaim.map((howToClaimData, id) => {
                    return (
                      <div key={id}>
                        <h6 className='mb-2'>
                          <strong>{howToClaimData.subtitle}</strong>
                        </h6>
                        <p className='mb-5'>
                          {howToClaimData?.details
                            .split(/(?=\s\d+\.)/)
                            .map((detail) => (
                              <p>{detail}</p>
                            ))}
                        </p>
                      </div>
                    );
                  })}
                  <div>
                    {/*<h6>Download Claim form</h6>*/}
                    <a href={file}>
                      <h6>Download Notification Claim form</h6>
                    </a>
                  </div>
                </section>

                <br></br>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(HowToClaimPage);
