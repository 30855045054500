import { Button, dialog } from "nq-component";

class ProductDetailsPresenter {
  constructor(
    view,
    findObjectUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
    saveFileUseCase
  ) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.saveFileUseCase = saveFileUseCase;
    this.product = null;
  }

  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getProduct())
      .then(() => this.getCoverage())
      .then(() => this.getCoverageDetails())
      .then(() => this.getCoverageDetailDatas())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getProduct() {
    this.product = this.view.getProduct();
    this.view.setProduct(this.product);
    // if (!this.product) {
    const id = this.view.getProductId();
    const query = {
      where: {
        id,
      },
      include: ["all"],
      // include: ["others", "coverages", "coverages.details", "coverages.details.detail_datas"],
    };
    return this.findObjectUseCase
      .execute("products", query)
      .then(([product]) => {
        this.product = product;
        this.view.setProduct(this.product);
      });
    // }
  }
  getCoverage() {
    this.product = this.view.getProduct();
    // if (!this.product) {
    const id = this.view.getProductId();
    const query = {
      where: {
        product: { id },
      },
      sort: { createdAt: 1 },
      include: ["product", "details"],
    };
    return this.findObjectUseCase
      .execute("coverages", query)
      .then((coverages) => {
        this.view.setCoverages(coverages);
      });
    // }
  }

  getCoverageDetails() {
    this.product = this.view.getProduct();
    // if (!this.product) {
    const id = this.view.getProductId();
    const query = {
      where: {
        product: { id },
      },
      sort: { createdAt: 1 },
      include: ["all"],
    };
    return this.findObjectUseCase
      .execute("coverage_details", query)
      .then((coverages_details) => {
        this.view.setCoverageDetails(coverages_details);
      });
  }

  getCoverageDetailDatas() {
    this.product = this.view.getProduct();
    // if (!this.product) {
    const id = this.view.getProductId();
    const query = {
      where: {
        plan: { id },
      },
      sort: { createdAt: 1 },
      include: ["all"],
    };
    return this.findObjectUseCase
      .execute("coverage_detail_datas", query)
      .then((coverages_detail_datas) => {
        this.view.setCoverageDetailDatas(coverages_detail_datas);
      });
    // }
  }

  onClickBack() {
    this.view.navigateBack();
  }

  onClickBack2 = () => {
    this.view.navigateBack();
  };
  proceedToForm(product) {
    console.log("hahah", product);

    if (product.name === "PLAN F") {
      dialog.fire({
        html: (
          <>
            <div>
              <h4>
                EMAIL THE "ONLINE@MICI.COM.PH" TO GET THE QUOTATION OR TO AVAIL
                THE SAID PLAN.
              </h4>
            </div>
            <Button
              className=" mb-3"
              style={{ float: "right" }}
              onClick={() => dialog.close()}
            >
              Ok
            </Button>
          </>
        ),
        footer: false,
      });
    } else {
      this.view.navigateTo(
        "/product/" + this.view.getProductId(),
        this.view.getProduct()
      );
    }
  }
}

export default ProductDetailsPresenter;
