import BasePage from "../../../base/BasePage";
import { findObjectUseCase } from "../../../domain/object";
import { NavBar } from "nq-component";
import React from "react";
import Image from "../../../assets/img/undraw_small_town_re_7mcn (1).svg";
import PrivacyPolicyPresenter from "./PrivacyPolicyPresenter";
import file from "../../../files/private_policy/THE MERCANTILE INSURANCE CO - BREACH INCIDENT PROCEDURE.pdf";

class PrivacyPolicyPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new PrivacyPolicyPresenter(this, findObjectUseCase());
    this.state = {
      privacyPolicy: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setPrivacyPolicy(privacyPolicy) {
    this.setState({ privacyPolicy });
  }

  getPrivacyPolicy() {
    return this.state.privacyPolicy;
  }

  render() {
    const privacyPolicy = this.state.privacyPolicy;
    return (
      <>
        <main
          className="container mainContent1 hidescroll px-lg-5 m-0 p-0"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 mb-lg-5 mt-lg-5 p-0">
            <div className="px-lg-5 mx-lg-5 m-0 p-0">
              {/*<div className="container bg-white">*/}
              {/*  <div className="h-100 d-flex align-items-center">*/}
              {/*    <div className="text-center w-100 px-lg-5">*/}
              {/*<img*/}
              {/*  className="img-fluid login-img mb-4 mt-5"*/}
              {/*  width="600"*/}
              {/*  src={Image}*/}
              {/*/>*/}
              {/*<h2 className="pt-5 mb-3">*/}
              {/*  <strong>{terms.title}</strong>*/}
              {/*</h2>*/}
              {/* <iframe
                src={file}
                className="w-100"
                style={{ height: "100vh" }}
              /> */}
              <h1>"Your Privacy Policy Details"</h1>
              {/*  </div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default PrivacyPolicyPage;
