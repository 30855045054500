class termsAndConditionPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.terms = null;
  }
  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getTerms())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getTerms() {
    this.terms = this.view.getTerms();
    this.view.setTerms(this.terms);

    const query = {
      include: ["all"],
    };
    return this.findObjectUseCase
      .execute("terms_and_condition", query)
      .then(([terms]) => {
        this.terms = terms;
        this.view.setTerms(this.terms);
      });
  }
}
export default termsAndConditionPresenter;
