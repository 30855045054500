import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import Image from "../../assets/img/Important-Advisory.jpg";
import PrivacyPolicyPresenter from "../policy/privacy-policy/PrivacyPolicyPresenter";
import { findObjectUseCase } from "../../domain/object";
import faqPresenter from "./faqPresenter";

class FaqPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new faqPresenter(this, findObjectUseCase());
    this.state = {
      faq: [],
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setFaq(faq) {
    this.setState({ faq });
  }

  getFaq() {
    return this.state.faq;
  }

  render() {
    const faq = this.state.faq;
    return (
      <>
        <main
          className="container mainContent1 hidescroll mw-100  m-0 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 mt-5 mb-5 p-0">
            <div className="px-lg-5 mx-lg-5 m-0 p-0">
              <div className="container bg-white">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center w-100 px-lg-5">
                    <img
                      className="img-fluid login-img mb-4 mt-5"
                      width="400"
                      src={Image}
                    />
                    <h4 className="mb-3">
                      <strong>Important Advisories</strong>
                    </h4>
                  </div>
                </div>
                <section className="mb-5 px-lg-5 mx-2">
                  {/* {faq.map((faq, id) => {
                    return (
                      <div key={id}>
                        <h6 className='mb-2'>
                          <strong>{faq.title}</strong>
                        </h6>
                        <p className='mb-5'>{faq.details}</p>
                      </div>
                    );
                  })} */}
                  Your Important Advisories Details.
                </section>
                <br></br>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default FaqPage;
