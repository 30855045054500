import withRouter from "../../withRouter";
import React from "react";
import BasePage from "../../base/BasePage";
import CartViewFormPresenter from "./CartViewFormPresenter";
import Beneficiary from "../product-form/components/Beneficiary/Beneficiary";
import { Button, InputString, InputDate, InputSelect } from "nq-component";
import { findObjectUseCase } from "../../domain/object";
import withContext from "../../withContext";
import InputRadioGroup from "../../components/InputRadioGroup";
import country from "../../country.json";
import dateDiff from "../../dateDiff";
import FilePreview from "../../components/FilePreview";
import isImage from "../../isImage";
import getFilename from "../../getFilename";

class CartViewForm extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      carts: [],
      customer: {},
      beneficiaries: [{}],
      itineraries: [{}],
      isChecked: true,
      file: [],
    };
    this.presenter = new CartViewFormPresenter(this, findObjectUseCase());
  }
  componentDidMount() {
    this.presenter.componentDidMount();
  }
  setCartInfo(carts) {
    this.setState({ carts });
  }
  setCustomer(customer) {
    this.setState({ customer });
  }

  setBeneficiaries(beneficiaries) {
    this.setState({ beneficiaries });
  }
  setItineraries(itineraries) {
    this.setState({ itineraries });
  }

  setFiles(file) {
    this.setState({ file });
  }

  goBack() {
    this.navigateTo("/cart");
  }

  render() {
    const customer = this.state.carts.customer;
    const order = this.state.order;
    const beneficiaries = this.state.beneficiaries;
    const itineraries = this.state.itineraries;
    const progress = this.state.progress;
    const isChecked = this.state.isChecked;
    const file = this.state.file;

    console.log("ITEM", itineraries)

    console.log("files", file);
    const images = file.filter((f) => isImage(f));
    const nonImages = file.filter((f) => !isImage(f));

    console.log("images", images);
    console.log("nonImage", nonImages);

    return (
      <>
        <main
          className="container mainContent1 hidescroll mw-100 p-0 m-0 px-lg-5 "
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 mx-lg-5 ">
            <div className="container p-0 m-0 px-lg-5  ">
              <div className="px-lg-5 py-lg-5 mt-lg-3 p-3 mt-1 pt-4 mb-4 pb-lg-4 bg-white ">
                <div className="container-fluid">
                  <div className="d-flex container-fluid justify-content-center mb-lg-3 mb-3 ps-0 pe-0 me-0">
                    <div className="d-flex flex-column flex-fill">
                      <form className="needs-validation" noValidate="">
                        <div className="container p-0 m-0 d-flex align-items-center">
                          <h4 className="ms-0 mb-4">APPLICATION FORM</h4>
                        </div>
                        <h5 className="p-0 m-0">
                          <i className="bi bi-people me-1"></i>CLIENT'S INFO
                        </h5>
                        <hr />
                        <small className="mb-2">
                          <strong>NAME OF ASSURED</strong>
                        </small>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="firstname"
                            field="firstName"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Firstname <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="middleName"
                            field="middleName"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Middlename <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="lastName"
                            field="lastName"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Lastname <span className="text-danger">*</span>
                          </label>
                        </div>
                        <small className="mb-3">
                          <strong>MAILING ADDRESS</strong>
                        </small>

                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            field="address"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Complete Address
                          </label>
                        </div>

                        <small>
                          <strong>CONTACT INFORMATION</strong>
                        </small>
                        <div className="form-floating mb-3 mt-3">
                          <InputString
                            required
                            type="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Email"
                            field="email"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Email <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            required
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            field="mobileNumber"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <InputString
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            field="landlineNumber"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">Telephone</label>
                        </div>

                        <small>
                          <strong>PERSONAL INFORMATION</strong>
                        </small>
                        <div className="container p-0 m-0 mt-3 d-flex justify-content-center">
                          <div className="row w-100">
                            <div className="col-12 pe-0 ps-0">
                              <div className="form-floating mb-3">
                                <InputDate
                                  required
                                  className="form-control"
                                  type="date"
                                  id="date"
                                  placeholder="Date"
                                  field="birthDate"
                                  object={customer}
                                  disabled
                                />
                                <label htmlFor="floatingInput">
                                  Birth Date
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container p-0 m-0 d-flex justify-content-center">
                          <div className="row w-100">
                            <div className="col-6 pe-0 ps-0">
                              <div className="form-floating mb-3">
                                <InputString
                                  required
                                  type="text"
                                  className="form-control"
                                  id="floatingInput"
                                  placeholder="Nationality"
                                  field="nationality"
                                  object={customer}
                                  disabled
                                />
                                <label for="floatingInput">
                                  Nationality
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                            </div>
                            <div className="col-6 pe-0 mt-2  ps-lg-5">
                              <InputRadioGroup
                                required
                                name="gender"
                                document={customer}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-floating mb-4">
                          <InputString
                            type="text"
                            className="form-control"
                            id="floatingInput"
                            placeholder="Occupation/Designation"
                            field="occupation"
                            object={customer}
                            disabled
                          />
                          <label htmlFor="floatingInput">
                            Occupation / Designation
                          </label>
                        </div>

                        {/* for beneficiaries */}

                        {beneficiaries.map((beneficiary, index) => {
                          return (
                            <React.Fragment key={beneficiary.id}>
                              <Beneficiary object={beneficiary} disabled />
                              {/*{beneficiaries.length > 1 && (*/}
                              {/*    <>*/}
                              {/*        <div class="container p-0 m-0 d-flex mb-2">*/}
                              {/*            <div class="row flex-fill justify-content-between">*/}
                              {/*                <div class="col-7 col-lg-4 pe-0"></div>*/}
                              {/*                <div class="col-5 col-lg-4">*/}
                              {/*                    <Button*/}
                              {/*                        type="button"*/}
                              {/*                        class="btn btn-danger text-white form-control mb-3"*/}
                              {/*                        onClick={this.onClickRemoveBeneficiary.bind(*/}
                              {/*                            this,*/}
                              {/*                            index*/}
                              {/*                        )}*/}
                              {/*                    >*/}
                              {/*                        <span className="d-flex justify-content-center align-items-center">*/}
                              {/*                          <i class="bi bi-dash-circle lh-1 me-2"></i>*/}
                              {/*                          REMOVE*/}
                              {/*                        </span>*/}
                              {/*                    </Button>*/}
                              {/*                </div>*/}
                              {/*            </div>*/}
                              {/*        </div>*/}
                              {/*    </>*/}
                              {/*)}*/}
                            </React.Fragment>
                          );
                        })}
                        {/*{beneficiaries.length !== 3 && (*/}
                        {/*    <div className="container p-0 m-0 d-flex mb-2">*/}
                        {/*        <div className="row flex-fill justify-content-between">*/}
                        {/*            <div className="col-6 col-lg-4 pe-0">*/}
                        {/*                <Button*/}
                        {/*                    className="btn btn-success text-white form-control mb-3"*/}
                        {/*                    type="button"*/}
                        {/*                    onClick={this.onClickAddBeneficiary.bind(this)}*/}
                        {/*                >*/}
                        {/*                    <i className="bi bi-plus-circle me-2"></i>ADD*/}
                        {/*                    BENEFICIARY*/}
                        {/*                </Button>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        {/* end of beneficiaries */}

                        {/* for itineraries */}

                        {itineraries.map((itinerary, index) => {
                          return (
                            <React.Fragment key={itinerary.id}>
                              {/*<Itinerary object={itinerary} required/>*/}
                              <div className="container p-0 m-0">
                                <small className="mt-2 mb-3">
                                  <strong>
                                    TRAVEL DETAILS{" "}
                                    <span className="text-danger">*</span>
                                  </strong>
                                </small>

                                <div className="col-12 mt-2">
                                  <InputSelect
                                    disabled
                                    className="mb-3"
                                    field="purpose"
                                    object={itinerary}
                                    options={[
                                      "Leisure",
                                      "Vacation",
                                      "Trip",
                                      "Tourism",
                                      "Training",
                                    ]}
                                  />
                                </div>

                                <small className="mb-2">
                                  <strong>
                                    ITINERARY{" "}
                                    <span className="text-danger">*</span>
                                  </strong>
                                </small>
                                <div className="container p-0 mt-2 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <small><strong><p className="mb-0">To</p></strong></small>
                                    </div>
                                    <div className="col-6 pe-0">
                                      <small><strong><p className="mb-0">From</p></strong></small>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 mt-2 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputSelect
                                          disabled
                                          className="mb-3"
                                          field="from"
                                          object={itinerary}
                                          options={country.contryList}
                                        />
                                        <label htmlFor="floatingInput">
                                          Country
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputSelect
                                          disabled
                                          className="mb-3"
                                          field="to"
                                          object={itinerary}
                                          options={country.contryList}
                                        />
                                        <label htmlFor="floatingInput">
                                          Country
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputString
                                          required
                                          className="mb-3"
                                          field="fromState"
                                          object={itinerary}
                                          disabled
                                        />
                                        <label htmlFor="floatingInput">
                                          State/City/Province
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputString
                                          required
                                          className="mb-3"
                                          field="toState"
                                          object={itinerary}
                                          disabled
                                        />
                                        <label htmlFor="floatingInput">
                                          State/City/Province
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 m-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <small className="mb-2">
                                        <strong>
                                          TRAVEL PERIOD{" "}
                                          <span className="text-danger">*</span>
                                        </strong>
                                      </small>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                      <small className="mb-2">
                                        <strong className="d-flex align-items-end">
                                          <span>
                                            TRAVEL DAY/s:&nbsp;{" "}
                                            {itinerary.startDate &&
                                              itinerary.endDate &&
                                              dateDiff(
                                                new Date(itinerary.startDate),
                                                new Date(itinerary.endDate)
                                              ) + 1}
                                          </span>
                                          <span className="badge rounded-pill bg-success fs-sm"></span>
                                        </strong>
                                      </small>
                                    </div>
                                  </div>
                                </div>
                                <div className="container p-0 m-0 d-flex">
                                  <div className="row flex-fill">
                                    <div className="col-6 pe-0">
                                      <div className="form-floating mb-3">
                                        <InputDate
                                          disabled
                                          className="form-control"
                                          type="date"
                                          id="date1"
                                          field="startDate"
                                          // onChange={this.startDateOnInput.bind(this, index)}
                                          object={itinerary}
                                        />
                                        <label htmlFor="floatingInput">
                                          Start
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="form-floating mb-3">
                                        <InputDate
                                          disabled
                                          className="form-control"
                                          type="date"
                                          id="date2"
                                          field="endDate"
                                          // onChange={this.endDateOnInput.bind(this, index)}
                                          object={itinerary}
                                        />
                                        <label htmlFor="floatingInput">
                                          End
                                          <span className="text-danger">*</span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*{itineraries.length > 1 && (*/}
                              {/*    <Button*/}
                              {/*        type="button"*/}
                              {/*        className="btn btn-danger text-white mb-3"*/}
                              {/*        onClick={this.onClickRemoveItinerary.bind(*/}
                              {/*            this,*/}
                              {/*            index*/}
                              {/*        )}*/}
                              {/*    >*/}
                              {/*        <i className="bi bi-dash-circle me-2"></i>REMOVE*/}
                              {/*    </Button>*/}
                              {/*)}*/}
                            </React.Fragment>
                          );
                        })}
                        <p>
                          <strong>
                            Total Days {this.state.carts.totalDays}
                          </strong>
                          <br />
                          (maximum of 365 days)
                        </p>
                        {/*{itineraries.length !== 3 && (*/}
                        {/*    <div className="container p-0 m-0 d-flex mb-2">*/}
                        {/*        <div className="row flex-fill justify-content-between">*/}
                        {/*            <div className="col-6 col-lg-4 pe-0">*/}
                        {/*                <Button*/}
                        {/*                    className="btn btn-success text-white form-control mb-3"*/}
                        {/*                    type="button"*/}
                        {/*                    onClick={this.onClickAddItinerary.bind(this)}*/}
                        {/*                >*/}
                        {/*                    <i className="bi bi-plus-circle me-2"></i>ADD*/}
                        {/*                    ITINERARY*/}
                        {/*                </Button>*/}
                        {/*            </div>*/}
                        {/*            <div className="col-6 col-lg-4"></div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*)}*/}

                        {/* end of beneficiaries */}

                        <hr />
                        <div className="mb-5 mt-3">
                          {images.length > 0 && (
                            <>
                              <label
                                className="form-label"
                                htmlFor="customFile"
                              >
                                <strong>
                                  Uploaded Images (tickets, etc.)
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <br />

                              <div className="align-content-md-start">
                                {images.map((result) => {
                                  return <FilePreview url={result} />;
                                })}
                              </div>
                              <hr />
                            </>
                          )}
                          {nonImages.length > 0 && (
                            <>
                              <label
                                className="form-label"
                                htmlFor="customFile"
                              >
                                <strong>
                                  Other Supporting Documents (files, etc.)
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <br />
                              <div className="align-content-md-start ">
                                {nonImages.map((file) => {
                                  return (
                                    <>
                                      <a href={file} target="_blank">
                                        {getFilename(file)}
                                      </a>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                        <Button onClick={this.goBack.bind(this)}>
                          Go Back
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(CartViewForm);
