class    ProductFormPresenter {
  constructor(view, saveObjectUseCase, saveFileUseCase, findObjectUseCase) {
    this.view = view;
    this.saveObjectUseCase = saveObjectUseCase;
    this.saveFileUseCase = saveFileUseCase;
    this.findObjectUseCase = findObjectUseCase;
  }

  init () {
    this.getData();
    this.fetchCountryCodes()
  }

  addToCartClick(isProceed) {
    this.view.showProgress();
    const customer = this.view.getCustomer();
    const mobileNumber = customer.mobileNumber.toString();
    let landlineNumber;
    if(customer.landlineNumber) {
      landlineNumber = customer.landlineNumber.toString();
    }
    const product = this.product
    const beneficiaries = this.view.getBeneficiaries();
    const itineraries = this.view.getItineraries();
    const user = this.view.getCurrentUser();
    const file = this.view.getFile();
    const totalDays = this.view.getTotalDays();
    const totalAmount = this.view.getTotalAmount();
    const currency = this.view.getCurrency();

    customer.user = user;
    customer.mobileNumber = mobileNumber;
    customer.landlineNumber = landlineNumber;
    itineraries.map((itinerary) => {
      delete itinerary.id;
    })
    beneficiaries.map((beneficiary) => {
      delete beneficiary.id;
    })
    const cart = {
      customer: customer,
      product: product,
      beneficiaries: beneficiaries,
      itineraries: itineraries,
      user: user,
      file: file.file,
      totalDays,
      totalAmount,
      currency: currency.currency
    };
    cart.acl = {
      read : [user.id,"*"],
      write : [user.id,"*"]
    }
    if(!cart.totalAmount > 0 && !cart.totalDays > 0 && !cart.totalDays < 366){
      this.view.showError("Your total days exceed the 365-day limit. Please correct your itinerary's travel days.");
      return;
    }
    this.saveObjectUseCase
        .execute("carts", cart)
        .then((cart) => {
          const order = [cart];
          this.view.hideProgress();
          isProceed ? this.view.navigateTo("/insurance/paymentreview", order) : this.view.navigateBack();
          this.view.setCurrentCartCount(this.view.getCurrentCartCount() + 1)
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
  }

  fetchCountryCodes = async () => {
    try {
      const response = await fetch('https://restcountries.com/v2/all');
      const data = await response.json();
      const codes = data.map((country) => ({
        code: `+${country.callingCodes[0]}`,
        name: country.name,
      }));
      console.log(codes)
    
    } catch (error) {
      
    }
  };

  getData () {
    const product = this.view.getArgument() || this.view.getProductId();
    const id = this.view.getParams().id;

    this.findObjectUseCase.execute("products", {where : {id : id}})
        .then(product => {
          this.product = product[0];
        })

    const where = {};
    const prices = {
      where : {plan : {id: id}}
    }

    this.findObjectUseCase.execute("prices", prices)
        .then(([prices]) => {
          this.prices = prices;
          this.view.setPrices(this.prices);
        })
  }

  makeid(length) {
    var result = "";
    var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
export default ProductFormPresenter;