class ForgotPresenter {
    constructor(view, resetPassword) {
        this.view = view;
        this.resetPassword = resetPassword;
    }

    forgotPassword(email) {
        this.view.showProgress()
        const options = {timeout: 0}

        this.resetPassword
            .execute(email, options)
            .then(() => {
                this.view.hideProgress()
                this.view.showSuccess("A link has been sent to your email")
            })
    }
}

export default ForgotPresenter;
  