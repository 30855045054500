import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import ProductsPresenter from "./ProductsPresenter";
import { Scroll } from "nq-component";
import { findObjectUseCase } from "../../domain/object";
import withContext from "../../withContext";
import Queue from "nq";
import formatCurrency from "../../formatCurrency";
import SkeletonElement from "../../components/Skeletons/SkeletenElement";

class ProductsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new ProductsPresenter(this, findObjectUseCase());
    this.state = {
      products: [],
      hasMore: false,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setMore(hasMore) {
    this.setState({ hasMore });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  setProducts(products) {
    this.setState({ products });
  }

  onClickProduct(product) {
    this.presenter.productClick(product);
  }

  onClickProductDetails(product) {
    this.presenter.productDetailClick(product);
  }

  render() {
    const user = this.getCurrentUser();
    const products = this.state.products;
    const hasMore = this.state.hasMore;
    const count = this.state.count;
    const progress = this.state.progress;
    console.log(products);

    return (
      <>
        <main
          className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2"
            style={{ maxWidth: "900px" }}
          >
            <div className="mt-lg-4 mb-lg-4">
              <h4
                className="ms-2"
                style={{ textShadow: "2px 2px 2px #FFFFFF" }}
              >
                Hello,
                <strong> {user.firstName}</strong>
              </h4>
              <h5
                className="ms-2"
                style={{ textShadow: "2px 2px 2px #FFFFFF" }}
              >
                <strong>Choose your Travel Insurance Plan</strong>
              </h5>
            </div>
            {/* <Scroll hasMore={hasMore} loadMore={this.loadMore.bind(this)}>             </Scroll> */}

            {products.map((product) => {
              return (
                <div className="product-services mb-2 px-2" key={product.id}>
                  <div className="card-ps d-flex bg-white align-items-center pt-lg-2 pb-lg-2 px-lg-3">
                    <img
                      src={product.image}
                      height="120"
                      width="120"
                      alt=""
                      className="m-2 me-1"
                      onClick={this.onClickProduct.bind(this, product)}
                      style={{ cursor: "pointer" }}
                    />
                    <div className="d-flex flex-column m-3 ms-1 me-1 w-100">
                      <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                        <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center text-nowrap">
                          <span>
                            {product.name}
                            <span className="text-success mx-1">
                              {formatCurrency(
                                product.insuranceAmount,
                                product.currency
                              )}
                            </span>
                          </span>

                          <span className="fs-6 mx-1 text-truncate">
                            {product.subtitle}
                          </span>
                        </h5>
                      </div>
                      <h6 className="mb-0 text-limit">{product.description}</h6>

                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <span
                          onClick={this.onClickProductDetails.bind(
                            this,
                            product
                          )}
                          className="text-secondary fs-xs mt-1 mb-1 fw-bold"
                          style={{ cursor: "pointer" }}
                        >
                          VIEW DETAILS
                        </span>

                        <button
                          className="btn btn-link"
                          onClick={this.onClickProduct.bind(this, product)}
                        >
                          <span
                            className="p-1 ps-3 pe-3 border border-primary rounded-pill"
                            style={{ fontSize: "0.8rem" }}
                          >
                            <strong>
                              {" "}
                              <i
                                className="bi bi-cart4 p-0 me-1"
                                style={{ lineHeight: "80%" }}
                              ></i>{" "}
                              BUY
                            </strong>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {progress &&
              [1, 2, 3, 4, 5, 6].map((id) => <SkeletonElement key={id} />)}
            {!progress && count === 0 && <h3>No Data Found</h3>}
          </div>
        </main>
      </>
    );
  }
}

export default withContext(ProductsPage);
