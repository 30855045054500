import { BrowserRouter, Routes, Route } from "react-router-dom";
// import VerifyPage from "./pages/verify/VerifyPage";
// import NotFoundPage from "./pages/notFound/NotFoundPage";
import SignInPage from "./pages/signin/SignInPage";
import SignUpPage from "./pages/signup/SignUpPage";
import ForgotPage from "./pages/forgot/ForgotPage";
import ResetPage from "./pages/reset/ResetPage";
import MainPage from "./pages/main/MainPage";
import AppProvider from "./AppProvider";
import Queue from "nq";
import TestPage from "./pages/test/TestPage";

// Queue.setUrl("https://api.mercantiletravel.com/v1");
// Queue.setUrl("https://api.live.mercantiletravel.com/v1");
// Queue.setApplicationId("6560588f36297abd70cb7433774d5e09");
Queue.setUrl("https://api.uat.mercantiletravel.com/v1");
Queue.setApplicationId("6560588f36297abd70cb7433774d5e09");

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<MainPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/test" element={<TestPage />} />
          {/*<Route path="/verify" element={<VerifyPage />} />*/}
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/reset/:token" element={<ResetPage />} />
          {/*<Route path="*" element={<NotFoundPage />} />*/}
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
