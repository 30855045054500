class DefinitionAndPenaltiesPresenter{
    constructor(view, findObjectUseCase) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
        this.privacyPolicy = null;
    }
    componentDidMount() {

    }
}
export default DefinitionAndPenaltiesPresenter;
