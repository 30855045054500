class SignUpPresenter {
  constructor(view, signUpUseCase) {
    this.view = view;
    this.signUpUseCase = signUpUseCase;
  }

  submit({ email, password, confirmPassword, firstName, lastName, ...others }) {
    console.log("fn", firstName)
    
    if (password !== confirmPassword) {
      this.view.showError("password must be the same");
      return;
    }

    if (password.length < 8 || password.length > 16) {
      this.view.showError("Password length must be 8-16 characters.");
      return;
    }

    const validation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])(?=.*\d)[A-Za-z\d!@"#$%&'()*+,\-.:;<=>?@[\\\]^_`{|}~°∞≈≠≤≥≡∑π´ˆ¨˜˚˘¸À-ÖØ-Ý]+$/

    if (!validation.test(password)) {
      this.view.showError("Password must contain at least one uppercase letter, one lowercase letter, one special character, and one number.")
      return
    }
    const fullName = firstName + " " + lastName
    const user = {
      username: email,
      email,
      password,
      firstName,
      lastName,
      name: fullName,
    };

    this.view.showProgress();
    this.signUpUseCase
      .execute(user)
      .then(() => {
        this.view.hideProgress();
        this.view
          .showSuccess(
            "Congratulations, your account has been successfully created."
          )
          .then(() => {
            this.view.navigateTo("/signin");
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default SignUpPresenter;
