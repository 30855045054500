import { InputString } from "nq-component";

function Beneficiary({ object, ...props }) {
  return (
    <div class="container p-0 m-0 d-flex">
      <div class="row flex-fill">
        <div class="col-7 pe-0">
          <div class="form-floating mb-3">
            <InputString
              required
              type="text"
              className="form-control"
              field="name"
              object={object}
              {...props}
            />
            <label for="floatingInput">
              Name of Beneficiary<span className="text-danger">*</span>
            </label>
            {/* <h1></h1> */}
          </div>
        </div>
        <div class="col-5">
          <div class="form-floating mb-3">
            <InputString
              required
              type="text"
              className="form-control"
              field="relationship"
              object={object}
              {...props}
            />
            <label for="floatingInput">
              <small>
                Relationship <span className="text-danger">*</span>
              </small>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Beneficiary;
