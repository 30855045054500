import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.jpg";
import BasePage from "../../base/BasePage";
import SignUpPresenter from "./SignUpPresenter";
import { signUpUseCase } from "../../domain/user";
import { Button } from "nq-component";
import { InputString } from "nq-component";
import { InputPassword } from "nq-component";
import "./SignUp.css";

class SignUpPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new SignUpPresenter(this, signUpUseCase());
    this.state = { user: {}, progress: false, isChecked: false };
  }

  showProgress() {
    this.setState({ progress: true });
  }

  hideProgress() {
    this.setState({ progress: false });
  }

  setCheck(event) {
    this.state.isChecked = event.target.checked;
  }

  formSubmit(event) {
    event.preventDefault();
    this.presenter.submit(this.state.user);
  }

  render() {
    const { user } = this.state;
    console.log("user");
    console.log("user");
    return (
      <div className="container-fluid p-0 bgroundContainer1">
        <div className="container mainContent1 p-lg-3 p-0 d-flex justify-content-center h-100">
          <div className="bg-white shadow rounded">
            <div className="container-fluid p-0" style={{ maxWidth: "420px" }}>
              <div className="company-logo2 d-flex justify-content-center text-center w-100">
                <img
                  className="align-items-start img-fluid login-img2 mb-4"
                  src={require("../../../src/assets/img/TravelInsurance.png")}
                />
              </div>
              <div className="container p-5 pt-0 pb-5 mt-lg-0 mt-3">
                <h2 className="fw-bold mb-3">Register</h2>
                <form onSubmit={this.formSubmit.bind(this)}>
                  <div className="row g-3 mb-3">
                    <div className="col-md-12">
                      <label className="form-label fs-sm">First Name</label>
                      <InputString
                        required
                        className="form-control"
                        field="firstName"
                        placeholder="e.g. Juan"
                        type="text"
                        object={user}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Last Name</label>
                      <InputString
                        required
                        className="form-control"
                        field="lastName"
                        placeholder="e.g. Dela Cruz"
                        type="text"
                        object={user}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Email Address</label>
                      <InputString
                        required
                        type="email"
                        autoComplete="nope"
                        className="form-control"
                        placeholder="e.g. username@domain.com"
                        field="email"
                        object={user}
                      />
                      <span className="form-text fs-xs">
                        We'll never share your email with anyone else.
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label fs-sm">Password</label>
                      <InputPassword
                        required
                        className="form-control"
                        placeholder="Password"
                        field="password"
                        object={user}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label fs-sm">
                        Confirm Password
                      </label>
                      <InputPassword
                        required
                        className="form-control"
                        placeholder="Password"
                        field="confirmPassword"
                        object={user}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          required={true}
                          // onChange={this.setCheck.bind(this)}
                          id="signup_cb_terms"
                        />
                        <label
                          className="form-check-label fs-xs"
                          for="signup_cb_terms"
                        >
                          By submitting this form, you agree to our
                          <br />
                          <Link
                            to="/TRAVEL PA POLICY TERMS AND CONDITIONSs.37bc1656e90633ab1ada.pdf"
                            target="_blank"
                          >
                            Terms and Conditions.
                          </Link>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mb-3">
                    <Button
                      progress={this.state.progress}
                      type="submit"
                      className="btn-primary w-50"
                    >
                      {this.state.progress ? "Please wait..." : "SIGNUP"}
                    </Button>
                  </div>
                  <div className="text-center">
                    <span className="fs-sm">
                      Already have an account?
                      <Link to="/signin" className="ms-2">
                        Sign in
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUpPage;
