import CryptoJS from "crypto-js";

function _getSignature(raw) {
  // let raw = "";
  // for (let key in data) {
  //   raw += data[key] ? data[key] : "";
  // }
  return CryptoJS.enc.Hex.stringify(CryptoJS.SHA512(raw));
}

const options = {
  merchantId: "0000001204238D6850BD",
  merchantKey: "25080728C7655D32DBC85E73721E2804",
};

function generateSignature(transaction) {
  const data = {
    transaction: {
      request_id: transaction.payment.id,
      notification_url:
        "https://api.mercantiletravel.com/v1/functions/6560588f36297abd70cb7433774d5e09/acceptPayment",
      response_url: "https://mercantiletravel.com/transaction-history",
      cancel_url: "https://mercantiletravel.com/transaction-history",
      pmethod: transaction.pmethod,
      pchannel: transaction.pchannel,
      payment_action: "url_link",
      collection_method: "single_pay",
      payment_notification_status: "1",
      payment_notification_channel: "1",
      amount: transaction.payment.amount,
      currency: "PHP",
    },
    customer_info: {
      fname: transaction.payment.firstname,
      lname: transaction.payment.lastname,
      mname: "-",
      email: transaction.payment.email,
    },
  };
  const raw =
    options.merchantId +
    data.transaction.request_id +
    data.transaction.notification_url +
    data.transaction.response_url +
    data.transaction.cancel_url +
    data.transaction.pmethod +
    data.transaction.payment_action +
    data.transaction.collection_method +
    data.transaction.amount +
    data.transaction.currency +
    data.transaction.payment_notification_status +
    data.transaction.payment_notification_channel +
    options.merchantKey;

  const rawCustomer =
    data.customer_info.fname +
    data.customer_info.lname +
    data.customer_info.mname +
    data.customer_info.email +
    options.merchantKey;

  const transactionSignature = _getSignature(raw);
  const customerSignature = _getSignature(rawCustomer);
  return { transactionSignature, customerSignature };
}

export default generateSignature;
