import BasePage from "../../base/BasePage";
import { Button } from "nq-component";
import NavBar from "../../components/NavBar";
import React from "react";
import { Link } from "react-router-dom";
import "./cartpage.css";
import CartPresenter from "./CartPresenter";
import { deleteObjectUseCase, findObjectUseCase } from "../../domain/object";
import formatCurrency from "../../formatCurrency";
import withRouter from "../../withRouter";
import { Checkbox } from "nq-component";

import Queue from "nq";
import withContext from "../../withContext";
import sortDescend from "../../sortDescend";

class CartPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new CartPresenter(
      this,
      findObjectUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      carts: [],
      hasMore: false,
      selected: [],
      currency: "PHP",
      paid: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setMore(hasMore) {
    this.setState({ hasMore });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  setCarts(carts) {
    this.setState({ carts });
  }

  onChangeSelect(index, id, checked) {
    const selected = this.state.selected;
    const carts = this.state.carts;

    if (checked) {
      selected.push(carts[index]);
      this.ordersCurrencyValidation(checked, index);
    } else {
      selected.map((item, index) => {
        if (item.id === id) {
          selected.splice(index, 1);
        }
      });
    }
    this.setState({ selected });
  }

  ordersCurrencyValidation(checked) {
    const orders = this.state.selected;
    let currency = this.state.currency;
    const checked1 = checked;
    let currencies = [];
    orders.map((order) => {
      currencies.push(order.currency.toUpperCase());
    });
    const uniq = [...new Set(currencies)];

    if (uniq.length > 1) {
      this.showError("You can't pay 2 different currency plan");
      orders.splice(orders.length - 1, 1);
      checked1.selected = false;
      return;
    }
    this.setState({ currency: uniq[0] });
  }

  viewForm(e) {
    this.presenter.viewCartInfo(e);
  }

  deleteCart(productId, e) {
    this.presenter.deleteProduct(productId);
  }

  hasDuplicateCustomers(array) {
    const seenCustomers = new Set();

    for (const item of array) {
      const customer = item.customer;
      const customerKey = `${customer.firstName}_${customer.lastName}_${customer.birthDate}_${customer.email}_${customer.mobileNumber}`;

      if (seenCustomers.has(customerKey)) {
        this.showError("Unable to Check Out - Duplicate Data Detected");
        return true;
      } else {
        seenCustomers.add(customerKey);
      }
    }

    return false;
  }

  onCheckOut(e) {
    const orders = this.state.selected;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");

    if (orders.length === 0) {
      this.showError("Please choose your order");
      return;
    } else {
      let check = true;

      orders.map((item) => {
        if (item.itineraries.length === 0) {
          this.showError("Selected Product has Expired");
          check = false;
        }

        item.itineraries.map((itinerary) => {
          const startDate = new Date(itinerary.startDate);
          const year = startDate.getFullYear();
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();

          const _date =
            year +
            "-" +
            month.toString().padStart(2, "0") +
            "-" +
            day.toString().padStart(2, "0");

          if (_date < date) {
            this.showError("Selected Product has Expired");
            check = false;
          } else {
          }
        });
      });

      if (!check) {
        return;
      }
    }

    this.ordersCurrencyValidation();
    // check duplication
    if (this.hasDuplicateCustomers(orders)) return;

    this.navigateTo("/insurance/paymentreview", orders);
  }

  render() {
    const carts = this.state.carts;
    const selected = this.state.selected;
    let paid = this.state.paid;
    const currency = this.state.currency;

    paid = selected.reduce((acc, cur) => {
      acc += cur.totalAmount;
      return acc;
    }, 0);

    console.log(carts);
    return (
      <>
        <main
          className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2"
            style={{ maxWidth: "800px" }}
          >
            <div className="d-flex flex-column justify-content-center mb-3 ">
              <h2
                className="d-flex align-items-center"
                style={{ textShadow: "2px 2px 2px #FFFFFF" }}
              >
                <span className="mb-1">
                  <i className="bi bi-cart me-1" />
                </span>
                Your Cart
              </h2>
              {carts.map((cart, index) => {
                const product = cart.product;
                const productId = cart;

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1;
                const day = currentDate.getDate();
                const date =
                  year +
                  "-" +
                  month.toString().padStart(2, "0") +
                  "-" +
                  day.toString().padStart(2, "0");

                cart.itineraries.map((itinerary) => {
                  const startDate = new Date(itinerary.startDate);
                  const year = startDate.getFullYear();
                  const month = startDate.getMonth() + 1;
                  const day = startDate.getDate();

                  const _date =
                    year +
                    "-" +
                    month.toString().padStart(2, "0") +
                    "-" +
                    day.toString().padStart(2, "0");

                  if (_date < date) {
                    cart.itineraries = [];
                  } else {
                  }
                });

                return (
                  <div key={index} className="product-services mb-4">
                    <div className="card-ps d-flex flex-column position-relative bg-white align-items-center mh-100">
                      <div className="container p-0 m-0 d-flex align-items-center">
                        {/* checkbox */}
                        <div className="form-check pe-2">
                          <Checkbox
                            checked={selected.includes(cart)}
                            onChange={this.onChangeSelect.bind(
                              this,
                              index,
                              cart.id
                            )}
                            id={cart.id}
                          />
                        </div>

                        {/* Image */}
                        <div className="d-flex border-start border-1 ">
                          {/* <img
                                      src={require("../../../src/assets/img/motorcar.png")}
                                      height="120"
                                      width="120"
                                      alt=""
                                      className="m-2 me-1"
                                    /> */}

                          <img
                            src={Queue.File.getFile(product.image)}
                            height="120"
                            width="120"
                            alt=""
                            className="m-2 me-1"
                          />

                          <div className="m-2 ms-2 ms-lg-3 pe-2 pe-lg-4 d-flex flex-column justify-content-evenly">
                            <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                              {/* title */}
                              <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center text-nowrap">
                                <span className="me-1">{product.name}</span>

                                <span className="text-success me-1 text-truncate">
                                  {formatCurrency(product.insuranceAmount)}
                                  <span className="fs-6 mx-1 ">
                                    {product.subtitle}
                                  </span>
                                </span>
                              </h5>
                              {/* description */}
                            </div>
                            <h6 className="mb-2 text-limit">
                              {product.description}
                            </h6>

                            <div className="d-flex align-items-center justify-content-between">
                              <h6 className="mb-0 text-limit fw-bold">
                                {cart.totalDays} Day(s)
                              </h6>

                              <h6 className="mb-0 text-limit fw-bold">
                                {formatCurrency(
                                  cart.totalAmount,
                                  cart.currency
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-ps-bottom container p-3 py-lg-2 m-0 d-flex justify-content-between align-items-center bg-light">
                        <Button
                          className="btn-light btn-outline-success border border-success rounded-pill ms-lg-4 lh-1"
                          onClick={this.viewForm.bind(this, productId)}
                        >
                          <span className="d-flex align-items-center fs-xs fw-bold">
                            <i className="bi bi-newspaper p-0 me-1"></i>
                            VIEW MY FORM
                          </span>
                        </Button>

                        <div className="flex">
                          {cart.itineraries.length === 0 && (
                            <span className="text-danger p-2">
                              This product has expired
                            </span>
                          )}
                          <Button
                            className="text-danger btn-light p-2"
                            onClick={this.deleteCart.bind(this, productId)}
                          >
                            <strong>
                              <span className="d-flex align-items-center fs-xs">
                                <i className="bi bi-trash me-1"></i>
                                REMOVE
                              </span>
                            </strong>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="payment-css container bg-white p-lg-5 p-3 ">
              <h6 className="py-4">
                <strong>Order Summary</strong>
              </h6>
              <div className="d-flex justify-content-between">
                <p className="ms-lg-4">Total Premium Products:</p>
                <p className="d-flex justify-content-end fw-bold">
                  {selected.length}
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="ms-lg-4">Amount to be paid:</p>
                <p className="d-flex justify-content-end fw-bold">
                  {formatCurrency(paid, currency)}
                </p>
              </div>
              <hr />

              <div className="mt-5 mb-0 pe-lg-3">
                <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                  <Button
                    onClick={this.onCheckOut.bind(this)}
                    className="btn btn-success btn-md me-lg-2  mb-1 text-white"
                  >
                    <span className="ps-5 pe-5">CHECKOUT</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(CartPage);
