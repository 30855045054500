import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import { Link } from "react-router-dom";
import { findObjectUseCase } from "../../domain/object";
import Image from "../../assets/img/undraw_city_life_gnpr.svg";
import termsAndConditionPresenter from "./termsAndCondtionPresenter";
import file from "../../files/terms_and_conditions/TRAVEL PA POLICY TERMS AND CONDITIONS.pdf";

class TermsandCondtnPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new termsAndConditionPresenter(this, findObjectUseCase());
    this.state = {
      terms: {},
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setTerms(terms) {
    this.setState({ terms });
  }

  getTerms() {
    return this.state.terms;
  }

  render() {
    const terms = this.state.terms;

    return (
      <>
        <main
          className="container mainContent1 hidescroll px-lg-5 m-0 p-0"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 m-0 p-0">
            <div className="px-lg-5 mx-lg-5 m-0 p-0">
              {/*<div className="container bg-white">*/}
              {/*  <div className="h-100 d-flex align-items-center">*/}
              {/*    <div className="text-center w-100 px-lg-5">*/}
              {/*<img*/}
              {/*  className="img-fluid login-img mb-4 mt-5"*/}
              {/*  width="600"*/}
              {/*  src={Image}*/}
              {/*/>*/}
              {/*<h2 className="pt-5 mb-3">*/}
              {/*  <strong>{terms.title}</strong>*/}
              {/*</h2>*/}
              {/* <iframe
                src={file}
                className="w-100"
                style={{ height: "100vh" }}
              /> */}
              <h1>Your Terms and Conditions Details.</h1>
              {/*  </div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default TermsandCondtnPage;
