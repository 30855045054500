import React from "react";
import "./Skeleton.css";

function SkeletonElement({ type }) {
  const classes = `skeleton ${type}`;

  return (
    <div className={classes}>
      <div className="skeleton-profile">
        <div>
          <div className="skeleton avatar"></div>
        </div>
        <div>
          <div className="skeleton title"></div>
          <div className="skeleton text"></div>
          <div className="skeleton text"></div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonElement;
