import BasePage from "../../base/BasePage";
import {NavBar} from "nq-component";
import React from "react";
import { Link } from "react-router-dom";

class ApplicationTravelPage extends BasePage {
  render() {
    return (
      <>
        <main
          className="container mainContent1 hidescroll mw-100 p-0 m-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div className="px-lg-5 mx-lg-5 ">
            <div className="container p-0 m-0 px-lg-5  ">
              <div className="px-lg-5 py-lg-5 mt-lg-3 p-3 mt-lg-2 mb-lg-4 bg-white ">
                <div className="container-fluid">
                  <div className="d-flex container-fluid justify-content-center mb-lg-3 mb-3 ps-0 pe-0 me-0">
                    <div className="d-flex flex-column flex-fill">
                      <div className="container p-0 m-0 d-flex align-items-center">
                        <h4 className="ms-0 mb-4">APPLICATION FORM</h4>
                      </div>
                      <h6 className="p-0 m-0">
                        <i className="bi bi-newspaper me-1"></i>TRAVEL INFO
                      </h6>
                      <hr />
                      <div className="form-floating mb-3 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name of Airline"
                        />
                        <label for="floatingInput">
                          Name of Airline <span className="text-danger">*</span>
                        </label>
                      </div>

                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Name of Airline"
                        />
                        <label for="floatingInput">
                          Purpose of Travel{" "}
                          <span className="text-danger">*</span>
                        </label>
                      </div>

                      <small className="mb-2">
                        <strong>
                          ITINERARY <span className="text-danger">*</span>
                        </strong>
                      </small>

                      <div className="container p-0 m-0 d-flex">
                        <div className="row flex-fill">
                          <div className="col-6 pe-0">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Street No."
                              />
                              <label for="floatingInput">From</label>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="House No."
                              />
                              <label for="floatingInput">To</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <small className="mb-2">
                        <strong>
                          TRAVEL PERIOD <span className="text-danger">*</span>
                        </strong>
                      </small>

                      <div className="container p-0 m-0 d-flex">
                        <div className="row flex-fill">
                          <div className="col-6 pe-0">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Street No."
                              />
                              <label for="floatingInput">From</label>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="House No."
                              />
                              <label for="floatingInput">To</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container p-0 m-0 d-flex mb-2">
                        <div className="row flex-fill">
                          <div className="col-6 col-lg-3 pe-0">
                            <button
                              type="button"
                              className="btn btn-success text-white form-control mb-3"
                            >
                              <span>
                                <i className="bi bi-plus-circle me-2"></i>ADD
                                ITINERARY
                              </span>
                            </button>
                          </div>
                          <div className="col-6"></div>
                        </div>
                      </div>

                      <small>
                        <strong>
                          BENEFICIARY/IES <span className="text-danger">*</span>
                        </strong>
                      </small>
                      <div className="container p-0 m-0 d-flex mt-2">
                        <div className="row flex-fill">
                          <div className="col-7 pe-0">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="Street No."
                              />
                              <label for="floatingInput">Name</label>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="form-floating mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="floatingInput"
                                placeholder="House No."
                              />
                              <label for="floatingInput">
                                <small>Relationship</small>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container p-0 m-0 d-flex mb-2">
                        <div className="row flex-fill">
                          <div className="col-6 col-lg-3 pe-0">
                            <button
                              type="button"
                              className="btn btn-success text-white form-control mb-3"
                            >
                              <span>
                                <i className="bi bi-plus-circle me-2"></i>ADD
                                BENEFICIARY
                              </span>
                            </button>
                          </div>
                          <div className="col-6"></div>
                        </div>
                      </div>

                      <div className="mb-4">
                        <label className="form-label" for="customFile">
                          <strong>
                            Upload Documents{" "}
                            <span className="text-danger">*</span>
                          </strong>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="customFile"
                          multiple
                        />
                      </div>

                      <small>
                        <strong>DECLARATION</strong>
                      </small>

                      <small>
                        I hereby apply for a personal accident insurance and
                        declare and warrant that the statement and answers are
                        full...
                      </small>

                      <div className="container p-0 m-0 d-flex mb-2 mt-2">
                        <div className="row flex-fill">
                          <div className="col-4 pe-0">
                            <button
                              type="button"
                              className="btn btn-secondary text-white form-control mb-3 p-1"
                            >
                              <small>
                                <strong>READ MORE</strong>
                              </small>
                            </button>
                          </div>
                          <div className="col-8"></div>
                        </div>
                      </div>

                      <div className="form-check p-0 m-0 d-flex mb-2 mt-2 align-items-center mb-5">
                        <input
                          className="form-check-input ms-0 me-2"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label lh-1"
                          for="flexCheckDefault"
                        >
                          <div>
                            <small>
                              By submitting this form, you agree to our
                            </small>
                          </div>
                          <div>
                            <small className="text-success">
                              Terms and Conditions.
                            </small>
                          </div>
                        </label>
                      </div>

                      <div className="mt-lg-1 mb-0 ">
                        <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                          <Link
                            to="/insurance/application"
                            className="btn btn-primary btn-md me-lg-2 mb-1 text-white"
                          >
                            GO BACK
                          </Link>

                          <Link
                            to="/insurance/cart"
                            className="btn btn-success btn-md me-lg-2 mb-1 text-white"
                          >
                            PROCEED TO CART
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default ApplicationTravelPage;
