import { Button, dialog } from "nq-component";

class ProductsPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {
      active: 1,
    };
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      include: ["coverages.details", "others"],
    };
    this.setProgress(true);
    return this.findObjectUseCase
      .execute("products", query)
      .then(({ count, objects }) => {
        console.log(objects);
        this.documents = this.documents.concat(objects);
        this.view.setMore(count > this.documents.length);
        this.view.setProducts(this.documents);
        this.setProgress(false);
      })
      .catch((error) => {
        this.setProgress(false);
        this.view.showError(error);
      });
  }

  setProgress(progress) {
    this.progress = progress;
    if (progress) {
      this.view.showProgress();
    } else {
      this.view.hideProgress();
    }
  }

  loadMore() {
    if (!this.progress) {
      this.current++;
      this.getData();
    }
  }

  // productClick(product) {
  //   console.log("Product", product);

  //   if (product.name === "Plan F") {
  //     dialog.fire({
  //       html: (
  //         <h4>
  //           EMAIL THE "ONLINE@MICI.COM.PH" TO GET THE QUOTATION OR TO AVAIL THE
  //           SAID PLAN.
  //         </h4>
  //       ),
  //     });
  //   }

  //   this.view.navigateTo("/product/" + product.id, product);
  // }

  productClick(product) {
    const prod = product.name === "PLAN F";

    if (prod) {
      dialog.fire({
        html: (
          <>
            <h4>
              EMAIL THE "ONLINE@MICI.COM.PH" TO GET THE QUOTATION OR TO AVAIL
              THE SAID PLAN.
            </h4>
            <Button
              className=" mb-3"
              style={{ float: "right" }}
              onClick={() => dialog.close()}
            >
              Ok
            </Button>
          </>
        ),
        footer: false,
      });
    } else {
      this.view.navigateTo("/product/" + product.id, product);
    }
  }

  productDetailClick(product) {
    this.view.navigateTo("/details/" + product.id, product);
  }
}

export default ProductsPresenter;
