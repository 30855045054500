import classNames from '../../classNames'
import React from 'react'

const defaultProps = {
  object: {},
  type: 'radio',
  category: 'Change Category',
}

function CustomInputRadioGroup({
  className,
  field,
  object,
  options,
  icons,
  category,
  ...props
}) {
  function optionsOnChange(e) {
    const selected = e.target.value
    if (selected) {
      object[field] = selected
    }
  }

  const classes = classNames('form-select fs-sm', className)
  let Default = object[field]

  return (
    <>
      <div className='p-0 m-0'>
        <h6>
          <strong>{category}</strong>
        </h6>
        {options.map((option) => {
          let defaultChecked = option.option === Default ? true : false
          return (
            <div className='card-ps d-flex flex-column position-relative bg-light rounded mt-2 mb-2 mb-lg-3'>
              <div className='m-2 p-2'>
                <input
                  className='form-check-input ms-2 me-3'
                  defaultChecked={defaultChecked}
                  type='radio'
                  name='inlineRadioOptions'
                  id='inlineRadio1'
                  value={option.option}
                  {...option.props}
                  onInput={optionsOnChange.bind(this)}
                  {...props}
                />
                <label className='form-check-label' htmlFor='inlineRadio1'>
                  <i className={option.icon}></i>
                  <strong className='ms-2'>{option.label}</strong>
                </label>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

CustomInputRadioGroup.defaultProps = defaultProps
export default CustomInputRadioGroup
