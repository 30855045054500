class OrderHistoryPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.limit = 40;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const user = this.view.getCurrentUser();
    this.where.user = user;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      sort: { createdAt: -1 },
      where: this.where,
      include: [
        "orders.itineraries",
        "orders.product",
        "orders.customer",
        "payment",
        "others",
      ],
    };
    this.setProgress(true);
    return this.findObjectUseCase
      .execute("transactions", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setMore(count > this.documents.length);
        this.view.setProducts(this.documents);
        this.setProgress(false);
      })
      .catch((error) => {
        this.setProgress(false);
        this.view.showError(error);
      });
  }

  setProgress(progress) {
    this.progress = progress;
    if (progress) {
      this.view.showProgress();
    } else {
      this.view.hideProgress();
    }
  }

  loadMore() {
    if (!this.progress) {
      this.current++;
      this.getData();
    }
  }

  productClick(transaction) {
    this.view.navigateTo("/orderdetails", transaction);
  }

  goBackClick(transaction) {
    this.view.navigateTo("/orders", transaction);
  }
}

export default OrderHistoryPresenter;
