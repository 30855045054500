import Queue from "nq";

class ResetPasswordUseCase {

    constructor() {
    }

    execute(email, options) {
        return new Queue.User().resetPassword(email, options)
    }

}

export default ResetPasswordUseCase;
