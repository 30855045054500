import classNames from "../../classNames";
import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "nq-component";

const defaultProps = {
    icon: "bi bi-list",
    logo: "/assets/images/logo.png",
};

function NavBar({
                    className,
                    onToggle,
                    icon,
                    logo,
                    children,
                    title,
                    cartClick,
                    cartCount,
                }) {
    const classes = classNames(
        className,
        "navbar navbar-expand-lg navbar-white bg-white"
    );
    const { collapsed, setCollapse } = React.useContext(Layout.Context);

    function click() {
        if (onToggle) {
            onToggle();
        } else {
            setCollapse(!collapsed);
        }
    }

    return (
        <nav className={classes}>
            <div className="container-fluid">
                <button
                    onClick={click}
                    type="button"
                    className="btn btn-sm btn-link fs-4 ps-0 text-dark"
                >
                    <i className={icon}></i>
                </button>
                <a href="# " onClick={cartClick} className="navbar-brand me-auto">
                    {title && title}
                </a>

                <Link className="me-3" to="/cart">
                    <i className="bi bi-cart text-dark m-0 fs-4"></i>
                    <span className="position-absolute top-25 start-25 translate-middle badge rounded-pill bg-success fs-xs mt-1">
            {cartCount}
          </span>
                </Link>
                {children}
            </div>
        </nav>
    );
}

NavBar.defaultProps = defaultProps;
export default NavBar;