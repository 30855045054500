function formatCurrency(number, currency, minimumFractionDigits = 0) {
  return number
    ?.toLocaleString("en-US", {
      style: "currency",
      currency: currency || "PHP",
      minimumFractionDigits,
    })
    .replace("PHP", "")
    .trimStart();
}

export default formatCurrency;
