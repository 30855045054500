import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import ProductDetailsPresenter from "./ProductDetailsPresenter";
import withContext from "../../withContext";
import formatCurrency from "../../formatCurrency";
import { Link } from "react-router-dom";
import { Button } from "nq-component";
import getProfile from "../../getProfile";
import de from "react-datepicker";
import { logDOM } from "@testing-library/react";

class ProductDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new ProductDetailsPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      product: null,
      coverage: [],
      coverageDetails: [],
      coverageDetailDatas: [],
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getProductId() {
    const params = this.getParams();
    return params.id;
  }

  setProduct(product) {
    this.setState({ product });
  }

  getProduct() {
    return this.state.product || this.getArgument();
  }

  setCoverages(coverage) {
    this.setState({ coverage });
  }

  getCoverages() {
    return this.state.coverage;
  }

  setCoverageDetails(coverageDetails) {
    this.setState({ coverageDetails });
  }

  getCoverageDetails() {
    return this.state.coverageDetails;
  }

  setCoverageDetailDatas(coverageDetailDatas) {
    this.setState({ coverageDetailDatas });
  }

  getCoverageDetailDatas() {
    return this.state.coverageDetailDatas;
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  onClickProceedToForm(product) {
    this.presenter.proceedToForm(product);
  }

  render() {
    const products = this.state.product;
    const coverage = this.state.coverage;
    const coverageDetails = this.state.coverageDetails;
    const coverageDetailDatas = this.state.coverageDetailDatas;

    return (
      <>
        <main
          className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 bg-white my-2"
            style={{ maxWidth: "800px" }}
          >
            {products && (
              <div className="container-fluid">
                {/* {products.banner ? (
                  <div className="d-flex container-fluid justify-content-center mb-lg-3 mb-3 ps-0 pe-0 me-0">
                    <img
                      effect="blur"
                      alt="banner"
                      src={products.banner}
                      width="100%"
                      className="img-fluid"
                    />
                  </div>
                ) : (
                  <div className="d-flex container-fluid mb-lg-3 mb-3 ps-0 pe-0 me-0">
                    <div className="">
                      <img
                        effect="blur"
                        alt="banner"
                        src="https://img.freepik.com/free-vector/diagonal-motion-lines-white-background_1017-33198.jpg?w=1380&t=st=1662455889~exp=1662456489~hmac=37f532f471a3dcd68ad2c961e96664aca784252824916d55cfc79fbcda020558"
                        width="100%"
                      />
                    </div>
                  </div>
                )} */}

                <div className="container p-0 m-0 d-grid mt-2 d-md-flex mb-2 lh-1 align-items-center">
                  <h4 className="fw-bold d-flex align-items-center text-nowrap">
                    {products.name}
                    <span className="text-success ms-2">
                      {formatCurrency(
                        products.insuranceAmount,
                        products.currency
                      )}
                    </span>
                    <span className="ms-2 fs-5">{products.subtitle}</span>
                  </h4>
                </div>

                <div className="cover mb-5 ">
                  <p className="mb-1">{products.description}</p>
                </div>

                <div className="d-flex justify-content-between cover mb-1 ">
                  <h5 className="mb-1">
                    <strong>COVERAGE</strong>
                  </h5>
                  <h5 className="mb-1">
                    <strong>Limit of Liability</strong>
                  </h5>
                </div>

                {coverage.length > 0 &&
                  coverage.map((coverage) => {
                    return (
                      <div key={coverage.id}>
                        <div className="d-flex mb-1">
                          <div className="col-8 bg-success text-white p-1">
                            {coverage.name}
                          </div>
                          <div className="col-4  flex-fill bg-dark text-center text-white p-1">
                            {coverage.limitLiability}
                          </div>
                        </div>
                        {coverageDetails.length > 0 &&
                          coverageDetails.map((coverageDetail) => {
                            if (coverageDetail?.coverage?.id === coverage?.id)
                              return (
                                <div className="d-flex mb-1">
                                  <div className="col-8 bg-light p-1">
                                    {coverageDetail.name ===
                                    "Delayed Departure" ? (
                                      <>
                                        <h5>Delayed Departure</h5>
                                        {/* {coverageDetail.name.map((name) => (
                                          <p>{name.name}</p>
                                        ))} */}
                                        <p>
                                          {coverageDetail.delayed.map((d) => (
                                            <p style={{ marginLeft: "20px" }}>
                                              {d.name}
                                            </p>
                                          ))}
                                        </p>
                                      </>
                                    ) : (
                                      coverageDetail.name
                                    )}
                                  </div>
                                  <div className="col-4  flex-fill text-center  bg-light p-1">
                                    {coverageDetail.limitLiability ===
                                    undefined ? (
                                      <>
                                        <p style={{ marginTop: "32px" }}>
                                          {coverageDetail.delayed.map((d) => (
                                            <p>{d.limit}</p>
                                          ))}
                                        </p>
                                      </>
                                    ) : (
                                      coverageDetail.limitLiability
                                    )}
                                  </div>
                                </div>
                              );
                          })}
                      </div>
                    );
                  })}

                <div className="cover mb-3 mt-5 ">
                  <h5 className="mb-1">
                    <strong>Other Conditions:</strong>
                  </h5>
                  <div className="d-flex flex-column ms-4">
                    {products.others.map((other, id) => (
                      <div
                        key={id}
                        className="p-1 me-2"
                        style={{ fontSize: "14px" }}
                      >
                        {other.name}
                      </div>
                    ))}
                  </div>
                </div>

                {products.schengen > 0 && (
                  <div className="accordion mb-5 mt-4 " id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button shadow-sm text-danger fw-bold collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Important Note: Schengen Restriction Policy
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <strong>
                            -Medical attention and treatment will be given in
                            case of accident including Covid-19.
                          </strong>{" "}
                          <br></br>
                          <br></br>
                          <span className="mt-4 mb-2">
                            SCHENGEN MEMBER STATES INCLUDING:
                          </span>
                          <br></br>Austria, Belgium, Czech Republic, Denmark,
                          Estonia, Finland, France, Germany, Greece, Hungary,
                          Iceland, Italy, Latvia, Liechtenstein, Lithuania,
                          Luxembourg, Malta, Netherlands, Norway, Poland,
                          Portugal, Slovakia, Slovenia, Spain, Sweden and
                          Switzerland.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="p-1 mt-lg-1 mb-0 ">
              <div className="d-grid gap-1 d-md-flex justify-content-md-end">
                <Button
                  onClick={this.onClickBack.bind(this)}
                  type="button"
                  className="btn btn-primary btn-md me-lg-2 mb-1 text-white"
                >
                  GO BACK
                </Button>
                <Button
                  onClick={this.onClickProceedToForm.bind(this, products)}
                  type="button"
                  className="btn btn-success btn-md me-lg-2 mb-1 text-white"
                >
                  PROCEED TO APPLICATION FORM
                </Button>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(ProductDetailsPage);
