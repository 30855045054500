import React from "react";
import {dialog} from "nq-component";
import resolvingPromise from "../resolvingPromise";
import Context from "../AppContext";
import ProgressDialog from "./ProgressDialog";
import ConfirmDialog from "./ConfirmDialog";

/**
 * responsible for global function use for all components
 */

class BasePage extends React.Component {
  showProgress() {
    this.setState({ progress: true });
  }

  hideProgress() {
    this.setState({ progress: false });
  }

  showProgressDialog() {
    const promise = resolvingPromise();
    dialog.fire({
      html: <ProgressDialog />,
      footer: false,
    });
    return promise;
  }

  hideProgressDialog() {
    dialog.close();
  }

  showError(error, title) {
    if (error instanceof Object) {
      return this.showError(error.message, title);
    }
    if (typeof error === "string") {
      const options = {
        title: title || "Error",
        message: error,
        icon: "bi bi-x-circle",
        type: "danger",
        positiveButton: "OKAY",
        negativeButton: false,
      };
      return this.showDialog(options);
    }
  }

  showSuccess(message, title) {
    const options = {
      title: title || "Success",
      message: message,
      icon: "bi bi-check-circle",
      type: "success",
      positiveButton: "OKAY",
      negativeButton: false,
    };
    return this.showDialog(options);
  }

  showDialog({ title, message, icon, type, ...options }) {
    const promise = resolvingPromise();
    dialog.fire({
      html: (
        <ConfirmDialog
          icon={icon}
          title={title}
          message={message}
          type={type}
        />
      ),
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
      ...options,
    });
    return promise;
  }

  showSuccessSnackbar(message) {
    // return showSuccessDialog(message, true);
  }

  getSchemas() {
    return this.context.schemas;
  }

  getCurrentUser() {
    return this.context.user;
  }

  setCurrentUser(user) {
    this.context.setGlobalState({ user });
  }

  getCurrentCartCount() {
    return this.context.cartCount;
  }

  setCurrentCartCount(cartCount) {
    this.context.setGlobalState({ cartCount });
  }

  setStatePromise(object) {
    const promise = resolvingPromise();
    this.setState(object, promise.resolve);
    return promise;
  }

  navigateTo(url, argument, options) {
    // const params = new URLSearchParams(argument).toString();
    const navigate = this.props.navigate;
    if (navigate) {
      navigate(url, { state: argument, ...options });
    } else {
      document.location.href = url;
    }
  }

  navigateBack() {
    this.navigateTo(-1);
  }

  navigateToClass(className) {
    this.navigateTo("/class/" + className);
  }

  getArgument() {
    return this.props.location.state;
  }

  getParams() {
    return this.props.params;
  }

  reload() {
    window.location.reload();
  }

  isMobile() {
    return window.innerWidth <= 768;
  }

  notificationPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        //  show image
      }
    });
  }

  showNotification(message, title, options) {
    const promise = resolvingPromise();
    const notification = new Notification(title, {
      body: message,
      icon: "images/logo.png",
    });
    notification.onclick = () => {
      notification.close();
      promise.resolve();
    };
    return promise;
  }
}

BasePage.contextType = Context;
export default BasePage;
