class CartPresenter {
    constructor(view, findObjectUseCase, deleteObjectUseCase) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
        this.deleteObjectUseCase = deleteObjectUseCase;
        this.limit = 10;
        this.current = 1;
        this.where = {user:{id:""}};
        this.documents = [];
        this.progress = false;
    }

    componentDidMount() {
        this.getData();
    }

    viewCartInfo(productId){
        this.view.navigateTo("/cart-view-form/" + productId.id, productId.id);
    }

    deleteProduct(productId) {
        const product = [productId];

        this.documents = this.documents.filter(order => !product.includes(order))
        this.view.showDialog({title: 'Delete Data?', message: 'Are you sure you want to delete?'})
            .then(()=>{
                this.deleteObjectUseCase.execute('carts', productId.id)
                    .then(()=>{
                        this.documents = this.documents.filter(order => !product.includes(order));
                        this.view.setCarts(this.documents);
                        this.view.showSuccess("Cart Deleted");
                        const currentCartCount = this.view.getCurrentCartCount();
                        this.view.setCurrentCartCount(currentCartCount - 1);

                    })
                    .catch(error=>{
                        this.view.showError(error);
                    })
            })
    }

    getData() {
        const user = this.view.getCurrentUser();
        this.where.user.id = user.id;
        const skip = (this.current - 1) * this.limit;
        const query = {
            count: true,
            sort: {createdAt: -1},
            // limit: this.limit,
            where: this.where,
            // skip,
            include: ['all']
        };

        this.setProgress(true);
        return this.findObjectUseCase.execute('carts', query)
            .then(({count, objects}) => {
                this.documents = this.documents.concat(objects);
                this.view.setMore(count > this.documents.length);
                this.view.setCarts(this.documents);
                this.setProgress(false);
            })
            .catch(error => {
                this.setProgress(false);
                this.view.showError(error);
            });
    }

    setProgress(progress) {
        this.progress = progress;
        if (progress) {
            this.view.showProgress();
        } else {
            this.view.hideProgress();
        }
    }

    loadMore() {
        if (!this.progress) {
            this.current++;
            this.getData();
        }
    }

}

export default CartPresenter;
