class PrivacyPolicyPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.privacyPolicy = null;
  }
  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getPrivacyPolicy())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getPrivacyPolicy() {
    this.privacyPolicy = this.view.getPrivacyPolicy();
    this.view.setPrivacyPolicy(this.privacyPolicy);


    const query = {
      include: ["all"],
    };
    return this.findObjectUseCase
      .execute("privacy_policy", query)
      .then(([privacyPolicy]) => {
        this.privacyPolicy = privacyPolicy;
        this.view.setPrivacyPolicy(this.privacyPolicy);
      });
  }
}
export default PrivacyPolicyPresenter;
