import BasePage from "../../base/BasePage";
import MakePaymentUseCase from "../../usecases/payment/MakePaymentUseCase";

class TestPage extends BasePage {

    click() {
        const payment = new MakePaymentUseCase();
        payment.execute();
    }

    render() {
        return (
            <>
                <button onClick={this.click.bind(this)}>
                    click
                </button>
            </>
        )
    }
}

export default TestPage;