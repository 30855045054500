import CryptoJS from 'crypto-js';

const options = {
    merchantId: '0000002109222823C71D',
    merchantKey: 'AC2FC9F1D744E31E3D7BB00B90E269B7',
    currency: "PHP",
    endpoint: 'https://payin.payserv.net/paygate/',
    username: 'mrcntlinsrnc',
    password: '2fq25Yt8NaGK',
}

//{"response_code":"GR001","response_advise":"Transaction is approved","pay_reference":"WPB9115032396790","response_message":"Transaction Successful","signature":"cc61736ecabcb87a6b36df0dcceba9f09b072d477ca1efaea15708469af2ea61204923ce47fdf4f84e9c3ec6ea633a35e040417e7579284a0e424de1d0c4498a","response_id":"9115755046982346587","merchant_id":"0000002109222823C71D","pchannel":"br_bdo_ph","request_id":"weerr","processor_response_id":"10dc3def-66fc-4422-9f0d-91d2c34e1f5d","timestamp":"2023-06-07T12:26:34.000+08:00"}
class MakePaymentUseCase {
    _getSignature(data) {
        let raw = '';
        for (let key in data) {
            raw += data[key] ? data[key] : '';
        }
        return CryptoJS.enc.Hex.stringify(CryptoJS.SHA512(raw));
    }

    execute() {
        const data = {
            "transaction": {
                "request_id": "weerr",
                "notification_url": "https://typedwebhook.tools/webhook/364cf82e-b0cd-4fcd-9cee-b6d7824f901f",
                "response_url": "https://payin.payserv.net/paygate",
                "cancel_url": "https://payin.payserv.net/paygate",
                "pmethod": "onlinebanktransfer",
                "pchannel": "br_bdo_ph",
                "payment_action": "url_link ",
                "collection_method": "single_pay",
                "payment_notification_status": "1",
                "payment_notification_channel": "1",
                "amount": "100.00",
                "currency": "PHP",
            },
            "customer_info": {
                "fname": "john",
                "lname": "doe"
            }
        }

        const transaction = {
            merchantId: options.merchantId,
            ...data.transaction,
            merchantKey: options.merchantKey
        };
        data.transaction.signature = this._getSignature(transaction);
        data.customer_info.signature = this._getSignature(data.customer_info);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Basic bXJjbnRsaW5zcm5jOjJmcTI1WXQ4TmFHSw==");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
        fetch("https://payin.payserv.net/paygate/transactions/", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
}

export default MakePaymentUseCase;