import Queue from 'nq';

class GetCurrentUserUseCase {
    constructor() {
    }

    execute(options) {
        return new Queue.User().getCurrentUser(options);
    }
}

export default GetCurrentUserUseCase;
