import BasePage from "../../base/BasePage";
import { NavBar } from "nq-component";
import React from "react";
import "./orderHistory.css";
import OrderHistoryPresenter from "./orderHistoryPresenter";
import { findObjectUseCase } from "../../domain/object";
import formatCurrency from "../../formatCurrency";
import Queue from "nq";
import { Scroll } from "nq-component";
import SkeletonElement from "../../components/Skeletons/SkeletenElement";
import { Link } from "react-router-dom";
import withContext from "../../withContext";
import dateFormat from "../../dateFormat";

class orderHistoryPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new OrderHistoryPresenter(this, findObjectUseCase());
    this.state = {
      transactions: [],
      hasMore: false,
    };
  }

  // componentDidMount() {
  //   this.presenter.componentDidMount();
  // }
  componentDidMount() {
    this.presenter.componentDidMount();

    const alreadyRefreshed = sessionStorage.getItem("alreadyRefreshed");

    if (!alreadyRefreshed) {
      setTimeout(() => {
        window.location.reload();
        // Set a flag in sessionStorage to indicate that the page has been refreshed
        sessionStorage.setItem("alreadyRefreshed", "true");
      }, 2000);
    } else {
      // If the page has already been refreshed, clear the flag so it can refresh again
      sessionStorage.removeItem("alreadyRefreshed");
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
  }

  setMore(hasMore) {
    this.setState({ hasMore });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  setProducts(transactions) {
    this.setState({ transactions });
  }

  onClickProduct(orderId, e) {
    this.presenter.productClick(orderId);
    // console.log(orderId)
  }

  render() {
    const user = this.getCurrentUser();
    const { transactions, hasMore, count, progress } = this.state;
    return (
      <>
        <main
          className="container-fluid mainContent1 hidescroll mw-100 p-0 px-lg-5"
          style={{ overflowY: "auto", overflowX: "hidden" }}
        >
          <div
            className=" mx-auto py-3 px-lg-5 py-lg-4 my-2 p-2"
            style={{ maxWidth: "800px" }}
          >
            <div className="d-flex flex-column justify-content-center mb-3 ">
              <h2
                className="d-flex align-items-center px-2"
                style={{ textShadow: "2px 2px 2px #FFFFFF" }}
              >
                <span className="mb-1">
                  <i className="bi bi-cart-check"></i>
                </span>
                My Orders
              </h2>
              <Scroll hasMore={hasMore} loadMore={this.loadMore.bind(this)}>
                {transactions.length >= 0 &&
                  transactions.map((transaction, id) => {
                    return (
                      <div className="product-services mb-2 px-2" key={id}>
                        <div className="card-ps d-flex bg-white align-items-center pt-lg-2 pb-lg-2 px-lg-3">
                          <div className="d-flex flex-column m-1 ms-4">
                            <div className="d-flex justify-content-between align-items-center my-2">
                              {/* transaction ID */}
                              <h5 className="m-0 fw-bold d-grid d-md-flex align-items-center">
                                <span>Transaction ID:</span>
                                <span className="text-success mx-1">
                                  {transaction.id}
                                </span>
                                {transaction?.payment?.status && (
                                  <span className="fw-bold">
                                    ({transaction?.payment?.status})
                                  </span>
                                )}
                                {transaction?.payment?.status !== "PAID" && (
                                  <span className="text-danger mx-1">
                                    (FAILED)
                                  </span>
                                )}
                              </h5>
                            </div>
                            <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                              <p className="m-0 me-2 ">Transaction Date:</p>
                              <span className="fw-bold ">
                                {dateFormat(transaction.createdAt)}
                              </span>
                            </div>
                            <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                              <p className="m-0 me-2">Transaction Type:</p>
                              <span className="fw-bold ">
                                {transaction?.payment?.transactionType}
                              </span>
                            </div>
                            <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center">
                              <p className="m-0 ">
                                Product Order(s):
                                <span className="fw-bold ms-2">
                                  {transaction.orders.length}
                                </span>
                              </p>
                            </div>
                          </div>

                          <div className="d-flex flex-column ms-auto align-items-center me-4">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              {/* Payment Status */}
                              {transaction?.payment?.paymentStatus ===
                              "PAID" ? (
                                <div className="bg-success text-white rounded-pill fs-sm fw-bold px-2">
                                  {transaction?.payment?.paymentStatus}
                                </div>
                              ) : (
                                <div className="bg-danger text-white rounded-pill fs-sm fw-bold px-2">
                                  {transaction?.payment?.paymentStatus}
                                </div>
                              )}
                            </div>
                            <div className="m-0 d-grid d-md-flex lh-1 align-items-center">
                              <p className="m-0 fs-4 fw-bold">
                                {formatCurrency(
                                  transaction?.payment?.paidAmount
                                )}
                              </p>
                            </div>
                            <div className="m-0 d-grid d-md-flex mb-2 lh-1 align-items-center my-3">
                              <p
                                className="m-0"
                                onClick={this.onClickProduct.bind(
                                  this,
                                  transaction.id
                                )}
                                style={{
                                  color: "inherit",
                                  cursor: "pointer",
                                }}
                              >
                                <u>View Details</u>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {progress &&
                  [1, 2, 3, 4, 5, 6].map((id) => <SkeletonElement key={id} />)}
                {!progress && count === 0 && <h3>No Data Found</h3>}
              </Scroll>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default withContext(orderHistoryPage);
