import getFilename from "../../getFilename";

function FilePreview({url}){

    function openInNewTab(){
        window.open(url);
    }

    return(
        <>
            <div className="m-2" style={{display : 'inline-block'}}>
                <div style={{background : 'skyblue', borderTopLeftRadius : 10, border :'solid'}}>
                    <div className="m-2" style={{width : 200, height : 100}}>
                        <img src={url} alt={url} style={{width : 200, height : 100, cursor : 'pointer'}} onClick={openInNewTab}/>
                    </div>
                </div>
                <div className="text-center">
                    {
                        getFilename(url)
                    }
                </div>
            </div>
        </>
    )

}
export default FilePreview;