import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { Menu } from "nq-component";
import { getCurrentUserUseCase, signOutUseCase } from "../../domain/user";
import { Routes, Route } from "react-router-dom";
import BasePage from "../../base/BasePage";
import { Layout } from "nq-component";
import { Progress } from "nq-component";
import { findObjectUseCase } from "../../domain/object";
import ProductsPage from "../products/ProductsPage";
import ProductDetailsPage from "../product-details/ProductDetailsPage";
import ProductFormPage from "../product-form/ProductFormPage";
import ApplicationTravelPage from "../application_travel/ApplicationTravelPage";
import CartPage from "../cart/CartPage";
import AccountPage from "../account/AccountPage";
import Logo from "../../assets/logo.svg";
import PaymentReview from "../payment/paymentReview";
import PaymentSuccess from "../payment/paymentSuccess";
import FaqPage from "../faq/faqPage";
import TermsCondtnPage from "../terms/termsAndCondition";
import PrivatePolicyPage from "../policy/privacy-policy/privacyPolicyPage";
import DefinitionAndPenaltiesPage from "../policy/definition-and-penalties/DefinitionAndPenaltiesPage";
import NotFoundPage from "../notFound/NotFoundPage";
import WelcomePage from "../welcome/welcomePage";
import OrderHistoryPage from "../orders/orderHistoryPage";
import OrderDetailsPage from "../orders/orderDetailsPage";
import OffCanvas from "../../components/OffCanvas";
import CartViewForm from "../cart view form/CartViewForm";
import NavBar from "../../components/NavBar";
import withContext from "../../withContext";
import PaymentFailed from "../payment/paymentFailed";
import HowToClaimPage from "../how-to-claim/HowToClaimPage";
import HomePage from "../homepage/HomePage";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      findObjectUseCase(),
      signOutUseCase()
    );
    this.state = { cartCount: 0 };
  }

  // getCartCount() {
  //   return this.props.params;
  // }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   this.presenter.componentDidUpdate(prevProps, prevState);
  // }

  signOutClick() {
    this.presenter.signOutClick();
  }

  cartClick() {
    this.navigateTo("cart");
  }

  // setCartCount(cartCount){
  //   this.setState({cartCount});
  // }

  render() {
    const cartCount = this.state.cartCount;
    const user = this.getCurrentUser();

    if (user === undefined) {
      return <Progress />;
    }
    const settings = [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-check",
      },
    ];
    const privacyPolicy = [
      {
        name: "Privacy Policy",
        route: "/privacy-policy",
        icon: "bi bi-shield-check",
      },
      {
        name: "Definition and Penalties",
        route: "/definition-and-penalties",
        icon: "bi bi-exclamation-triangle",
      },
    ];
    const menus = [
      {
        name: "Home",
        icon: "bi bi-house",
        route: "/",
      },
      {
        name: "Choose Your Plans",
        icon: "bi bi-pencil-square",
        route: "/Products",
      },
      {
        name: "Transaction History",
        icon: "bi bi-cart",
        route: "transaction-history",
      },
      {
        name: "How to Claim",
        icon: "bi bi-card-checklist",
        route: "howToClaimPage",
      },
      {
        name: "Policy",
        icon: "bi bi-building",
        route: privacyPolicy,
      },
      {
        name: "Important Advisories",
        icon: "bi bi-info-circle",
        route: "frequently-ask-question",
      },
      {
        name: "Terms and Condition",
        icon: "bi bi-question-circle",
        route: "terms-and-condition",
      },
      //{
      //  name: "Terms of use",
      //  icon: "bi bi-file-earmark-check",
      //  route: "terms-of-use",
      //},
      {
        name: "Settings",
        icon: "bi bi-sliders",
        route: settings,
      },
    ];

    const myCart = this.getCurrentCartCount();
    return (
      <Layout>
        <OffCanvas signOutClick={this.signOutClick.bind(this)}>
          <nav className="navbar-light">
            <div className="text-center w-100">
              <img className="img-fluid login-img" width="250" src={Logo} />
            </div>
            <hr className="dropdown-divider bg-dark mt-1" />
            <div className="text-center"></div>

            <Menu menus={menus} />
          </nav>
        </OffCanvas>

        <main className="vh-100 d-flex flex-column bgroundContainer1">
          <NavBar
            cartCount={myCart}
            cartClick={this.cartClick.bind(this)}
          ></NavBar>
          <Routes>
            <Route exact path={"/"} element={<HomePage />} />
            <Route exact path={"/products"} element={<ProductsPage />} />
            <Route
              exact
              path={"/details/:id"}
              element={<ProductDetailsPage />}
            />
            <Route exact path={"/product/:id"} element={<ProductFormPage />} />
            <Route
              exact
              path={"/insurance/application/travel"}
              element={<ApplicationTravelPage />}
            />
            <Route exact path={"/cart"} element={<CartPage />} />
            <Route path={"/cart-view-form/:id"} element={<CartViewForm />} />
            <Route
              exact
              path={"/transaction-history"}
              element={<OrderHistoryPage />}
            />
            <Route
              exact
              path={"/orderdetails"}
              element={<OrderDetailsPage />}
            />
            <Route
              exact
              path={"/insurance/paymentreview"}
              element={<PaymentReview />}
            />

            <Route
              exact
              path={"/insurance/paymentsuccess"}
              element={<PaymentSuccess />}
            />

            <Route
              exact
              path={"/insurance/paymentfailure"}
              element={<PaymentFailed />}
            />

            <Route
              exact
              path={"/frequently-ask-question"}
              element={<FaqPage />}
            />
            <Route
              exact
              path={"/privacy-policy"}
              element={<PrivatePolicyPage />}
            />
            <Route
              exact
              path={"/definition-and-penalties"}
              element={<DefinitionAndPenaltiesPage />}
            />
            <Route
              exact
              path={"/terms-and-condition"}
              element={<TermsCondtnPage />}
            />

            <Route exact path={"/account"} element={<AccountPage />} />

            <Route
              exact
              path={"/howToClaimPage"}
              element={<HowToClaimPage />}
            />

            <Route exact path={"*"} element={<NotFoundPage />} />

            <Route exact path={"/welcome"} element={<WelcomePage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withContext(MainPage);
