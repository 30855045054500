import React from "react";
import { Link } from "react-router-dom";
import SignInPresenter from "./SignInPresenter";
import { signInUseCase, signOutUseCase } from "../../domain/user";
import { InputPassword } from "nq-component";
import { InputString } from "nq-component";
import { Button } from "nq-component";
import BasePage from "../../base/BasePage";
import "./SignIn.css";
import { findObjectUseCase } from "../../domain/object";

class SignInPage extends BasePage {
  constructor() {
    super();
    this.presenter = new SignInPresenter(
      this,
      signInUseCase(),
      findObjectUseCase(),
      signOutUseCase()
    );
    this.state = { user: {}, progress: false };
  }

  formSubmit(e) {
    e.preventDefault();
    this.presenter.submit(this.state.user);
  }

  showProgress() {
    this.setState({ progress: true });
  }

  hideProgress() {
    this.setState({ progress: false });
  }

  render() {
    const { user } = this.state;

    // useEffect(() => {
    //   setTimeout(() => {
    //     setShow(true);
    //   }, 2000);
    // }, []);

    return (
      <div className="vh-100 d-flex flex-column justify-content-center p-0 bgroundContainer1">
        <div className="container mainContent1 p-lg-3 p-0 d-flex justify-content-center align-items-center ">
          <div className="bg-white shadow rounded">
            {/* <div className="col-md-6 border-end border-1 ps-0 pe-0"></div> */}
            <div className="container-fluid p-0" style={{ maxWidth: "420px" }}>
              <div className="company-logo1 d-flex justify-content-center text-center w-100">
                <img
                  className="align-items-start img-fluid login-img1 mb-4"
                  src={require("../../../src/assets/img/TravelInsurance.png")}
                  // src={require("../../../src/assets/logo.svg")}
                />
              </div>
              <div class="container p-5 pt-0 pb-4 mt-lg-0 mt-3">
                <h2 className="fw-bold mb-3">Login</h2>
                <form onSubmit={this.formSubmit.bind(this)}>
                  <div className="row g-3 mb-3">
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Email Address</label>
                      <InputString
                        required
                        type="email"
                        autoComplete="nope"
                        className="form-control"
                        placeholder="Email Address"
                        field="email"
                        object={user}
                      />
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">Password</label>
                      <InputPassword
                        required
                        className="form-control"
                        placeholder="Password"
                        field="password"
                        object={user}
                      />
                    </div>
                  </div>
                  <div className="text-end mb-3">
                    <div className="mb-3 text-end">
                      <Link to="/forgot" className="fs-sm ms-2">
                        Forgot your password?
                      </Link>
                    </div>
                  </div>
                  <div className="text-center mb-3">
                    <Button
                      progress={this.state.progress}
                      type="submit"
                      className="btn-primary w-50"
                    >
                      {this.state.progress ? "Please wait..." : "LOGIN"}
                    </Button>
                    <br />
                  </div>
                  <div className="text-center">
                    <span className="fs-sm">
                      Don't have an account?
                      <Link to="/signup" className="text-decoration-none">
                        Sign up
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <TestModal /> */}
      </div>
    );
  }
}

export default SignInPage;
