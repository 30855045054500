class OrderDetailsPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const productId = this.view.getArgument();
    const skip = (this.current - 1) * this.limit;
    this.where.id = productId;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      // include: ["coverages.details", "others"],
      include: [
        "orders.itineraries",
        "orders.product",
        "orders.customer",
        "payment",
        "user",
        "others",
      ],
    };
    this.setProgress(true);
    return this.findObjectUseCase
      .execute("transactions", query)
      .then(({ count, objects }) => {
        this.documents = this.documents.concat(objects);
        this.view.setMore(count > this.documents.length);
        this.view.setTransactions(this.documents);
        this.setProgress(false);
      })
      .catch((error) => {
        this.setProgress(false);
        this.view.showError(error);
      });
  }

  setProgress(progress) {
    this.progress = progress;
    if (progress) {
      this.view.showProgress();
    } else {
      this.view.hideProgress();
    }
  }

  loadMore() {
    if (!this.progress) {
      this.current++;
      this.getData();
    }
  }

  productClick(product) {
    this.view.navigateTo("/product", product);
  }

  productDetailClick(product) {
    this.view.navigateTo("/details", product);
  }

  payNow(transaction) {
    // console.log(transaction.transactions[0])
    this.view.navigateTo(
      "/insurance/paymentreview",
      transaction.transactions[0]
    );
  }
}

export default OrderDetailsPresenter;
