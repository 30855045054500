/**
 * responsible for get the current user and current subscriptions schema
 */
import promiseAny from "../../promiseAny";

class MainPagePresenter {
  constructor(view, getCurrentUserUseCase, findObjectUseCase, signOutUseCase) {
    this.view = view;
    this.getCurrentUserUseCase = getCurrentUserUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.signOutUseCase = signOutUseCase;
  }

  componentDidMount() {
    this.getUser();
  }

  // componentDidUpdate(prevProps, prevState) {
  //   console.log(prevProps)
  //   console.log(this.view.getCartCount())
  // const prevClassName = prevProps.params.className;
  // const newClassName = this.view.getCartCount();
  // console.log(prevClassName);
  // console.log(newClassName);
  // if className change
  // if (prevClassName !== undefined) {
  //   this.getUser();
  // }
  // }

  setCartCount(cartCount) {
    this.view.setCartCount(cartCount);
  }

  getUser() {
    this.view.showProgress();
    this.getCurrentUserUseCase
      .execute()
      .then((user) => {
        // this.setCartCount();
        this.view.hideProgress();
        this.view.setCurrentUser(user);
        const query = { where: { user: { id: user.id } } };

        Promise.resolve()
          .then(() => this.findObjectUseCase.execute("carts", query))
          .then((cartCount) => {
            // if(cartCount.length <= 0){
            //   this.view.setCurrentCartCount(0);
            //   return;
            // }
            // console.log('cart count find object',cartCount);
            // this.setCartCount(cartCount.length);
            this.view.setCurrentCartCount(cartCount.length);
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        if (error.code === 209) {
          this.view.navigateTo("/signin");
        } else {
          this.view.navigateTo("/signin");
        }
      });
  }
  signOutClick() {
    const options = {
      title: "Confirm",
      message: "Are you sure you want to sign out?",
      positiveButton: "SIGN OUT",
    };
    this.view
      .showDialog(options)
      .then(() => this.signOutUseCase.execute())
      .then(() => {
        this.view.navigateTo("/signin");
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }
}

export default MainPagePresenter;
